import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';
import { LoaderService } from '../service/loader/loader.service';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisableButtonApi]'
})
export class DisableButtonApiDirective {
  isLoading = false;
  constructor(private _loader: LoaderService, private renderer: Renderer2,
    private el: ElementRef) {
    this._loader.isLoading.subscribe(res => {
      if (res) {
        this.isLoading = true;
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'no-drop');
      } else {
        this.isLoading = false;
        this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
      }
    });
  }
  @HostListener('click')
  onClick() {
    if (this.isLoading) {
      (<HTMLButtonElement>this.el.nativeElement).disabled = true;

    } else {
      (<HTMLButtonElement>this.el.nativeElement).disabled = false;

    }
  }
}
