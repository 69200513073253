import { style } from '@angular/animations';
import { Injectable } from '@angular/core';
import Swal, { SweetAlertCustomClass, SweetAlertResult } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { AuditSuccessDraftCancelSettings, AuditSuccessDraftConfirmSettings } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor(
    private translate: TranslateService
  ) { }

  confirm(title: string = 'Are you sure?', text: string = 'This can\'t be undone!',
    confirmButtonText: string = 'Yes, delete it!', cancelButtonText: string = 'No, keep it',
    type: string = 'warning') {
    return new Promise((resolve: any, reject: any) => {
      Swal.fire({
        title: this.translate.instant(title),
        text: this.translate.instant(text),
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant(confirmButtonText),
        cancelButtonText: this.translate.instant(cancelButtonText),
        customClass: {
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false
      }).then((result: SweetAlertResult) => {
        if (result.value) {
          resolve(true); return;

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });

    });
  }

  confirmSuccess(title: string = 'Are you sure?', text: string = 'This can\'t be undone!',
    confirmButtonText: string = 'Yes, delete it!',
    type: string = 'success') {
    return new Promise((resolve: any, reject: any) => {
      Swal.fire({
        title: this.translate.instant(title),
        text: this.translate.instant(text),
        icon: 'success',
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant(confirmButtonText),
        customClass: {
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false
      }).then((result: SweetAlertResult) => {
        if (result.value) {
          resolve(true); return;

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });
    });
  }

  // confirmation pop-up with two buttons
  confirmSuccesssWithTwoButton(title: string = 'Are you sure?', text: string = 'This can\'t be undone!',
    confirmButtonText: string = 'Yes, delete it!', cancelButtonText: string = 'No, keep it',
    type: any = 'success') {
    return new Promise((resolve: any, reject: any) => {
      Swal.fire({
        title: this.translate?.instant(title),
        text: this.translate?.instant(text),
        icon: 'success',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.translate?.instant(confirmButtonText),
        cancelButtonText: this.translate?.instant(cancelButtonText),
        customClass: {
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false
      }).then((result: SweetAlertResult<any>) => {
        if (result?.value) {
          resolve(true); return;

        } else if (result?.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });

    });
  }

  customConfirmSuccess(title: string = 'Are you sure?', text: string = 'This can\'t be undone!', customSettingsForCancel: AuditSuccessDraftCancelSettings = {
    cancelButtonText: '',
    customClassForCancel: '',
    cancelButtonRouterLink: '',
    enableCancelButtonRouterLink: false
  }, customSettingsForConfirm: AuditSuccessDraftConfirmSettings = {
    confirmButtonText: '',
    customClassForConfirm: '',
    confirmButtonRouterLink: '',
    enableConfirmButtonRouterLink: false
  }, innerHtml: any = '') {
    return new Promise((resolve: any, reject: any) => {
      Swal.fire({
        title: this.translate?.instant(title),
        text: this.translate?.instant(text),
        icon: 'success',
        html: innerHtml !== '' ? innerHtml : '',
        showCancelButton: true,
        showConfirmButton: true,
        allowOutsideClick: false,
        confirmButtonText: customSettingsForConfirm?.enableConfirmButtonRouterLink ? `<a href="${customSettingsForConfirm?.confirmButtonRouterLink}" class="au-btn au-btn--primary au-m--1">${customSettingsForConfirm?.confirmButtonText}</a>` : this.translate?.instant(customSettingsForConfirm?.confirmButtonText),
        cancelButtonText: customSettingsForCancel?.enableCancelButtonRouterLink ? `<a href="${customSettingsForCancel?.cancelButtonRouterLink}" class="au-btn au-btn--secondary au-m--1">${customSettingsForCancel?.cancelButtonText}</a>` : this.translate?.instant(customSettingsForCancel?.cancelButtonText),
        customClass: {
          confirmButton: 'au-btn au-btn--primary au-m--1',
          denyButton: '',
          cancelButton: customSettingsForCancel?.customClassForCancel ? customSettingsForCancel?.customClassForCancel : ''
        },
        buttonsStyling: false,
        willOpen(popup: HTMLElement) {
          const confirmButton: any = popup.childNodes?.[2]?.childNodes?.[1];
          const cancelButton: any = popup.childNodes?.[2]?.childNodes?.[3];
          if (customSettingsForConfirm?.customClassForConfirm !== '') {
            // override sweet alert custom button display styles
            confirmButton.style.display = 'contents';
            confirmButton.className = customSettingsForConfirm?.customClassForConfirm;
          }
          if (customSettingsForCancel?.customClassForCancel !== '') {
            // override sweet alert custom button display styles
            cancelButton.style.display = 'contents';
            cancelButton.className = customSettingsForCancel?.customClassForCancel;
          }
        }
      }).then((result: SweetAlertResult<any>) => {
        if (result?.value) {
          resolve(true); return;

        } else if (result?.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });

    });
  }

  confirmObject(object: {
    headText: string,
    subText: string,
    buttonOpt_1: string,
    buttonOpt_2: string
  }) {
    return new Promise((resolve: any, reject: any) => {
      Swal.fire({
        title: this.translate.instant(object.headText),
        text: this.translate.instant(object.subText),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant(object.buttonOpt_1),
        cancelButtonText: this.translate.instant(object.buttonOpt_2),
        customClass: {
          confirmButton: 'au-btn au-btn--primary au-m--1',
          cancelButton: 'au-btn au-btn--secondary au-m--1'
        },
        buttonsStyling: false
      }).then((result: SweetAlertResult) => {
        if (result.value) {
          resolve(true); return;

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });
    });
  }

  customAlert(object: {
    headText: string,
    subText: string,
    buttonOpt_1: string,
    buttonOpt_2: string,
    showCancelButton: boolean,
    icon: any,
  }, width: string = '400px', className: SweetAlertCustomClass = {confirmButton: 'au-btn au-btn--primary au-m--1', cancelButton: 'au-btn au-btn--secondary au-m--1'}, buttonsStyling: boolean = false, html: string = '') {
    return new Promise((resolve: any, reject: any) => {
      Swal.fire({
        title: this.translate.instant(object.headText),
        text: this.translate.instant(object.subText),
        icon: object.icon,
        showCancelButton: object.showCancelButton,
        confirmButtonText: this.translate.instant(object.buttonOpt_1),
        cancelButtonText: this.translate.instant(object.buttonOpt_2),
        width: width,
        html: html,
        customClass: className,
        buttonsStyling: buttonsStyling
      }).then((result: SweetAlertResult) => {
        if (result.value) {
          resolve(true); return;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false); return;
        }
      });
    });
  }
}
