import { environment } from 'src/environments/environment';
import { browserData, LocalStorageKey } from 'src/app/core/constants';
import { StorageService } from 'src/app/core/helper/storage.service';
import { Injectable, EventEmitter, RendererFactory2, Renderer2 } from '@angular/core';
import { dateFormat } from '../constants';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { UserProfile } from '../interface/profile.interface';
import { DropdownId } from '../interface/dropdown.interface';
import {Buffer} from 'node_modules/buffer';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {
  sidebarSubMenuItem: EventEmitter<string> = new EventEmitter();
  public permissionGuardValue: boolean;
  public userProfile: UserProfile | any;
  public logoImage: string;
  public randomIntegerValue: number = 0;
  public layoutOptions: LayoutOptions = {
    // two possible values: light, dark
    theme: 'light',
    // two possible values: ltr, rtl
    dir: 'ltr',
    // fixed value. shouldn't be changed.
    layout: 'vertical',
    // four possible values: full, iconbar, overlay, mini-sidebar
    sidebartype: 'full',
    // two possible values: fixed, absolute
    sidebarpos: 'fixed',
    // two possible values: fixed, absolute
    headerpos: 'fixed',
    // two possible values: full, boxed
    boxed: 'full',
    // six possible values: skin(1/2/3/4/5/6)
    navbarbg: 'skin6',
    // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin5',
    // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6'
  };

  private render: Renderer2;

  constructor(private datePipe: DatePipe,
    private _cookie: CookieService,
    private _storage: StorageService, rendererFactory: RendererFactory2) {
    this.render = rendererFactory.createRenderer(null, null);
  }

  get random() {
    return Math.floor(Math.random() * 6) + 1;
  }

  // To get Random String
  getRandomString(length: number = 5, onlyAlphabets: boolean = false): string {
    let result = '';
    const characters = onlyAlphabets ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // used to find percentage
  getPercentage(value: number |string, total: number): number | any {
    try {
      const percentage: any = Math.round((+value / total) * 100);
      if (percentage === Infinity || percentage === isNaN(percentage)) {
        return;
      }
      return +percentage;

    } catch (error) {
      return + value;
    }
  }

  public convertToServerDate(date: any = new Date(), format: string = dateFormat.serverDate) {
    try {
      return moment(date).format(format);
    } catch (error) {
      return date;
    }
  }

  public convertToCreateTimeApiFormat(date: any = new Date(), format: string = dateFormat.createTimeApiFormat) {
    try {

      // return moment(date).format('YYYY-DD-MM');
      return this.datePipe.transform(date, format);
    } catch (error) {
      return date;
    }
  }

  public convertToCreateTimeApiTimeFormat(date: any = new Date(), format: string = dateFormat.addTime) {
    try {

      // return moment(date).format('YYYY-DD-MM');
      return this.datePipe.transform(date, format);
    } catch (error) {
      return date;
    }
  }

  public convertToClientDate(date: any = new Date(), format: string = dateFormat.userDate) {
    try {
      return this.datePipe.transform(date, format);
    } catch (error) {
      return date;
    }
  }

  public convertDateToTimesheetFormat(date: any, format: string = dateFormat.timeSheetDate) {
    try {
      // this.datePipe.transform(date, format);
      return moment(date).format(format);
    } catch (error) {
      return date;
    }
  }

  public getCurrentWeek(date: moment.Moment = moment(), format: string = dateFormat.serverDate) {
    const currentDate = moment(date);
    const weekStart = currentDate.clone().startOf('week');
    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format(format));
    }
    return days;
  }

  public getPreviousWeek(date: moment.Moment = moment(), format: string = dateFormat.timeSheetDate) {
    const currentDate = moment(date).subtract(1, 'days');
    const weekStart = currentDate.clone().startOf('week');
    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format(format));
    }
    return days;
  }

  public getNextWeek(date: moment.Moment = moment(), format: string = dateFormat.timeSheetDate) {
    const currentDate = moment(date).add(1, 'days');
    const weekStart = currentDate.clone().startOf('week');
    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format(format));
    }
    return days;
  }

  public getDateRange(type: string | any = 'days', range: number = 12, format: string = dateFormat.timeSheetDateFilter, date: moment.Moment = moment()) {
    const t = type;
    const currentDate = moment(date).subtract(range, t);
    const weekStart = currentDate.clone().startOf('day');
    const days = [];

    for (let i = 0; i <= range; i++) {
      days.push(moment(weekStart).add(i, t).format(format));
    }
    return days;
  }

  public stringToDate(date: moment.Moment = moment(), format: string = dateFormat.date) {
    try {
      return moment(date).format(format);
    } catch (error) {
      return date;
    }
  }

  public stringToTime(time: string, date: any = moment(), format: string = dateFormat.fullDate) {
    try {
      return moment(`${date} ${time}`).format(format);
    } catch (error) {
      return time;
    }
  }

  public getMovementDate(date: any, format: string = dateFormat.movementTime) {
    try {
      // this.datePipe.transform(date, format);
      return moment(date).format(format);
    } catch (error) {
      return date;
    }
  }

  public getNextYearDate(date: moment.Moment = moment(), format: string = dateFormat.fullDate) {
    return moment(date).add(1, 'years').format(format);
  }

  public getPreviousYearDate(date: moment.Moment = moment(), format: string = dateFormat.fullDate) {
    return new Date(moment(date).subtract(1, 'years').format(format));
  }

  public convertStringToJson(data: string | any) {
    for (let i = 0; i < data.length; i++) {
      data = data.replace('\'', '"');
    }
    return JSON.parse(data);
  }

  public getUserDetails(): UserProfile {
    return this._storage.getLocalStorage(LocalStorageKey.userDetails);
  }

  public setUser(data: any) {
    // Checking production variable for setting values
    if (environment.production && environment.protocol === 'https://') {
      document.cookie = `${LocalStorageKey.userLogin}=${JSON.stringify(data)};path=/;Domain=.${environment.cookieDomain};secure`;
    } else if (environment.production && environment.protocol === 'http://') {
      document.cookie = `${LocalStorageKey.userLogin}=${JSON.stringify(data)};path=/;Domain=.${environment.cookieDomain}`;
    } else {
      document.cookie = `${LocalStorageKey.userLogin}=${JSON.stringify(data)};path=/;`;
    }
  }

  public get getUser(): any {
    if (this._cookie.get(LocalStorageKey.userLogin)) {
      return   this.converTokenToUserData(this._cookie.get(LocalStorageKey.userLogin));
    }
    return;
  }

  public  converTokenToUserData(token: string) {
    try {
      const jsonPayload = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
      return jsonPayload;
    } catch {
      return;
    }
  }

  public get isLogged(): any {
    return this.getUser;
  }

  // To validate email
  public isValidEmail(email: string | any) {
    if ((/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/).test(email)) {
      return (true);
    }
    return (false);
  }

  public deleteCookie() {
    return new Promise((resolve: any, reject: any) => {
      const cookies = document.cookie.split('; ');
      for (let c = 0; c < cookies.length; c++) {
        const d = window.location.hostname.split('.');
        while (d.length > 0) {
          const cookieBase = `${encodeURIComponent(cookies[c].split(';')[0].split('=')[0])}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${d.join('.')} ;path=`;
          const p = location.pathname.split('/');
          document.cookie = `${cookieBase}/`;
          while (p.length > 0) {
            document.cookie = cookieBase + p.join('/');
            p.pop();
          }
          d.shift();
        }
      }
      document.cookie.split('; ').map((v: string) => {
        document.cookie = `${v.split('=')[0]}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=.${environment.cookieDomain}`;
      });
      document.cookie = `${LocalStorageKey.userLogin}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      // document.cookie = 'key' + `=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      document.cookie = 'sso-sessionid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'retoken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'csrftoken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'redirect=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      resolve(true);
    });
  }

  invertColor(hex: string | any) {
    try {
      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        return '#000';
      }
      const rgb = this.hexToRgb(hex);
      if (rgb.r > 190) {
        return '#000';
      }
      return '#fff';
    } catch (error) {
      return '#fff';
    }
  }

  hexToRgb(hex: string | any): { r: number; g: number; b: number } {
    const result = (/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i).exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  percentageToDegrees(value: number): number {
    return value / 100 * 360;
  }

  get randomHex(): string {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return `#${(`000000${color}`).slice(-6)}`;
  }

  // detect browser version
  detectBrowserVersion(): string {
    const userAgent = navigator?.userAgent;
    const  matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    return matchTest[2] ? matchTest[2] : '1';
  }

  // detect browser name
  detectBrowserName(): string {
    const agent = window?.navigator?.userAgent.toLowerCase();
    switch (true) {
    case agent?.indexOf( browserData.edge ) > -1  || agent?.indexOf(browserData.edg) > -1:
      return  browserData.edge;
    case agent?.indexOf( browserData.opr) > -1 && !!(<any>window).opr:
      return browserData.opera;
    case agent?.indexOf(browserData.chrome) > -1 && !!(<any>window).chrome:
      return browserData.chrome;
    case agent?.indexOf(browserData.trident) > -1:
      return browserData.ie;
    case agent?.indexOf(browserData.firefox) > -1:
      return browserData.firefox;
    case agent?.indexOf(browserData.safari) > -1:
      return browserData.safari;
    default:
      return  browserData.other;
    }
  }

  // add cookies with date
  addCookie(name: string, value: string, expire: Date ) {
    document.cookie = `${name}=${value};path=/;expires = ${expire?.toUTCString()}`;
  }

  // get cookies
  getCookie(name: string) {
    if (this._cookie?.get(name)) {
      return JSON.parse(this._cookie?.get(name));
    }
  }

  // get date while we give number of day from current Date
  getDateWithRespectToDay(day: number): Date {
    return new Date( new Date().setTime(new Date().getTime() + (day * 24 * 60 * 60 * 1000)));
  }

  // this function is used for converting query params into strings
  queryParamsToString(params: object): string {
    const keys: string[] = Object.keys(params);
    const value: string[] = Object.values(params);
    let query = '';
    for (let i = 0; i < keys.length; i++) {
      if (value[i] !== '') {
        query += `${keys[i]}=${encodeURIComponent(value[i])}&`;
      }
    }
    return query;
  }

  // dropdown string to array that have comma
  setStringToArray(data: string, name: string = 'name'): {[params: string]: string}[] {
    const convertedValue = [];
    if (data) {
      const splittedValue = data.split(',');
      splittedValue.forEach( (singleValue: string) => {
        convertedValue.push({
          [name]: singleValue
        });
      });
    }
    return convertedValue;
  }

  // get string from dropdown value
  getStringDropDown (data: {name: string}[] | string): string {
    if (typeof (data) === 'string') {
      return  data;
    }
    if (data?.length === 0 || data === null || data === undefined) {
      return '';
    }
    let focalPersonNames = '';
    if (data?.length !== 0) {
      data?.forEach((person: {name: string}) => {
        if (focalPersonNames === '') {
          focalPersonNames = person?.name;
        } else {
          focalPersonNames = `${focalPersonNames},${person?.name}`;
        }
      });
    }
    return  focalPersonNames;
  }

  // get the value form dropdown
  getIdFromValue(value: DropdownId[] | any) {
    if (value) {
      const id = value?.map((element: DropdownId | any) => element?.id);
      return id;
    }
    return [];

  }

  // function used to get width of text
  getTextWidth(label: string | number, fontsize: number = 10, fontFamilyName: string = 'poppins'): number {
    const text = label?.toString();
    // creating canvas
    const canvas = this.render.createElement('canvas');
    const context =  canvas.getContext('2d');
    // adding font family and fontsize
    context.font = `${fontsize}px ${fontFamilyName}`;
    // measuring width
    return  context.measureText(`${text}W`)?.width;
  }

  // function creating random id
  randomId(label: string = ''): string  {
    const random = () => `-${Math.random() .toString(36).substring(2, 40)}`;
    return label + random()  +  random()  +  random();
  }

  downloadFiles(res: Blob | {file:string; name:string}| any) {
    let url = window.URL.createObjectURL(res);
    let a =  this.render.createElement('a');
    this.render.appendChild(document.body, a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = res.name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}

interface LayoutOptions {
  theme: string;
  dir: string;
  layout: string;
  sidebartype: string;
  sidebarpos: string;
  headerpos: string;
  boxed: string;
  navbarbg: string;
  sidebarbg: string;
  logobg: string;
}

