import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { SetupWizardService } from '../service/setup-wizard/setup-wizard.service';

@Injectable({
  providedIn: 'root'
})

export class ResolveSetupWizardGuard implements Resolve<any> {
  constructor(private _setupwizard: SetupWizardService) {}
   resolve() {
     return this._setupwizard.checkSetupWizardStatus();
   }
}
