import { BrowserCheckService } from './core/service/BrowserCheck/browser-check.service';
import { Component} from '@angular/core';
import { ServiceWorkerHandlerService } from './core/service/service-worker-handler.service';
import { JwtTokenService } from 'src/app/core/helper/jwt-token.service';
import { MfaValidateService } from 'src/app/core/service/mfa-validate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private _sw: ServiceWorkerHandlerService,
    private _browserCheck: BrowserCheckService,
    private  _jwtService : JwtTokenService,
    private _mfaService: MfaValidateService
  ) {
    this._sw.checkForUpdate();
    this._sw.updateCheckOnNavigation();
    this._browserCheck.checkForUpdate();
    const user = _jwtService.getTokenData;
    if (this._mfaService.getRedirectionStatus === 'true') {
      this._mfaService.mfaAuth = false;
    } else if (this._mfaService.getRedirectionStatus === 'false') {
      this._mfaService.mfaAuth = true;
    } else if (user?.mfa_status && user?.mfa_mandatory) {
      this._mfaService.mfaAuth = true;
    } else {
      this._mfaService.mfaAuth = false;
    }
    const refreshToken = this. _jwtService.getRefreshToken;
    if (refreshToken !== '') {
      this._jwtService.startRefreshTokenTimer(refreshToken);
    }
  }
}
