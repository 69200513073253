import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { AdminUserService } from '../service/control-panel/admin-user.service';

@Injectable({
  providedIn: 'root'
})

export class ResolveAdminGuard implements Resolve<any> {
  constructor(private _admin: AdminUserService) {}
  resolve() {
    return this._admin.adminGetSidebarPermission();
  }
}
