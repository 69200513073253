
export const entityWizardEvent =  {
  overview: 'overview',
  scoring: 'scoring',
  summary: 'summary'
};

export const entityFunctionsWizardEvent =  {
  overview: 'overview',
  summary: 'summary'
};

export const entityWizardItems =  [
  {
    name: 'Overview',
    event: entityWizardEvent.overview
  },
  {
    name: 'Scoring',
    event: entityWizardEvent.scoring
  },
  {
    name: 'Summary',
    event: entityWizardEvent.summary
  }
];

export const entityWizardItemsNormal =  [
  {
    name: 'Overview',
    event: entityWizardEvent.overview
  },
  {
    name: 'Summary',
    event: entityWizardEvent.summary
  }
];

export const functionWizardItemsNormal =  [
  {
    name: 'Overview',
    event: entityFunctionsWizardEvent.overview
  },
  {
    name: 'Summary',
    event: entityFunctionsWizardEvent.summary
  }
];

export const departmentPageInfo = [
  {
    title: 'Entity Management',
    url: '/entity-management/entities'
  },
  {
    title: 'Departments',
    url: '/entity-management/departments'
  },
  {
    title: 'Create Department',
    url: ''
  }
];
export const entityPageInfo = [
  {
    title: 'Entity Management',
    url: '/entity-management/entities'
  },
  {
    title: 'Create  Entity',
    url: ''
  }
];

export const sectionPageInfo = [
  {
    title: 'Entity Management',
    url: '/entity-management/entities'
  },
  {
    title: 'Sections',
    url: '/entity-management/sections'
  },
  {
    title: 'Create Section',
    url: ''
  }
];

export const functionPageInfo = [
  {
    title: 'Entity Management',
    url: '/entity-management/entities'
  },
  {
    title: 'Functions',
    url: '/entity-management/functions'
  },
  {
    title: 'Create Functions',
    url: ''
  }
];

export const menuCreatorPageInfo = [
  {
    title: 'Settings',
    url: 'settings/menu-creator'
  }
];

export const departmentLabels =  {
  department: 'Department Name',
  description: 'description',
  entity: 'entity',
  sector: 'sector',
  section: 'section',
  parent: 'Parent',
  type: 'type',
  manager: 'manager',
  megaProcess: 'megaProcess',
  majorProcess: 'majorProcess',
  megaProcessSpace: 'Mega Process',
  majorProcessSpace: 'Major Process',
  rating: 'rating',
  departmentAdditionalDropdown1: 'department_additional_dropdown_1',
  departmentAdditionalDropdown2: 'department_additional_dropdown_2',
  departmentAdditionalDropdown3: 'department_additional_dropdown_3',
  departmentAdditionalDropdown4: 'department_additional_dropdown_4',
  departmentAdditionalDesignation1: 'department_additional_designation_1',
  departmentAdditionalUser1: 'department_additional_user_1',
  departmentAdditionalUser2: 'department_additional_user_2',
  departmentAdditionalUser3: 'department_additional_user_3',
  departmentAdditionalUser4: 'department_additional_user_4',
  refereDepartmentFocalPerson: 'refere_department_focal_person',
  departmentAdditionalMultiDropdown1: 'department_additional_multi_dropdown_1',
  departmentAdditionalMultiDropdown2: 'department_additional_multi_dropdown_2',
  departmentAdditionalMultiDropdown3: 'department_additional_multi_dropdown_3',
  departmentAdditionalMultiDropdown4: 'department_additional_multi_dropdown_4',
  departmentAdditionalText1: 'department_additional_text_1',
  departmentAdditionalText2: 'department_additional_text_2',
  departmentAdditionalText3: 'department_additional_text_3',
  departmentAdditionalText4: 'department_additional_text_4',
  departmentAdditionalTextarea1: 'department_additional_textarea_1',
  departmentAdditionalTextarea2: 'department_additional_textarea_2',
  departmentAdditionalTextarea3: 'department_additional_textarea_3',
  departmentAdditionalTextarea4: 'department_additional_textarea_4',
  departmentAdditionalTextarea5: 'department_additional_textarea_5',
  departmentFocalPerson: 'department_focal_person'
};
export const departmentCreateLabels =  {
  department: 'department',
  description: 'description',
  section: 'section',
  parent: 'parent',
  entity: 'entity',
  sector: 'sector',
  type: 'type',
  manager: 'manager',
  megaProcess: 'megaProcess',
  majorProcess: 'majorProcess',
  megaProcessSpace: 'Mega Process',
  majorProcessSpace: 'Major Process',
  rating: 'rating',
  departmentAdditionalDropdown1: 'department_additional_dropdown_1',
  departmentAdditionalDropdown2: 'department_additional_dropdown_2',
  departmentAdditionalDropdown3: 'department_additional_dropdown_3',
  departmentAdditionalDropdown4: 'department_additional_dropdown_4',
  departmentAdditionalDesignation1: 'department_additional_designation_1',
  departmentAdditionalDesignation2: 'department_additional_designation_2',
  departmentAdditionalDesignation3: 'department_additional_designation_3',
  departmentAdditionalDesignation4: 'department_additional_designation_4',
  departmentAdditionalUser1: 'department_additional_user_1',
  departmentAdditionalUser2: 'department_additional_user_2',
  departmentAdditionalUser3: 'department_additional_user_3',
  departmentAdditionalUser4: 'department_additional_user_4',
  refereDepartmentFocalPerson: 'refere_department_focal_person',
  departmentAdditionalMultiDropdown1: 'department_additional_multi_dropdown_1',
  departmentAdditionalMultiDropdown2: 'department_additional_multi_dropdown_2',
  departmentAdditionalMultiDropdown3: 'department_additional_multi_dropdown_3',
  departmentAdditionalMultiDropdown4: 'department_additional_multi_dropdown_4',
  departmentAdditionalText1: 'department_additional_text_1',
  departmentAdditionalText2: 'department_additional_text_2',
  departmentAdditionalText3: 'department_additional_text_3',
  departmentAdditionalText4: 'department_additional_text_4',
  departmentAdditionalTextarea1: 'department_additional_textarea_1',
  departmentAdditionalTextarea2: 'department_additional_textarea_2',
  departmentAdditionalTextarea3: 'department_additional_textarea_3',
  departmentAdditionalTextarea4: 'department_additional_textarea_4',
  departmentAdditionalTextarea5: 'department_additional_textarea_5',
  departmentFocalPerson: 'department_focal_person'
};
export const entityLabels =  {
  entityManager: 'manager',
  region: 'region',
  description: 'description',
  entity: 'name',
  entityLabel: 'entity',
  sector: 'sector',
  type: 'entity_type_new',
  entityType: 'Entity Type',
  manager: 'entity_managers',
  megaProcess: 'mega_process',
  majorProcess: 'major_process',
  megaProcessSpace: 'Mega Process',
  majorProcessSpace: 'Major Process',
  addressLineOne: 'ADDRESS LINE1',
  addressLineTwo: 'ADDRESS LINE2',
  country: 'country',
  state: 'state',
  city: 'city',
  zipCode: 'ZIP CODE',
  alternateContact: 'Alternative Contact Number',
  contact: 'contact_number',
  email: 'email',
  addressLineOneForm: 'address_line1',
  addressLineTwoForm: 'address_line2',
  zipCodeForm: 'zip_code',
  alternateContactForm: 'alternate_contact',
  entity_additional_user_1: 'entity_additional_user_1',
  entity_additional_user_2: 'entity_additional_user_2',
  entity_additional_user_3: 'entity_additional_user_3',
  entity_additional_user_4: 'entity_additional_user_4',
  entity_additional_dropdown_1: 'entity_additional_dropdown_1',
  entity_additional_dropdown_2: 'entity_additional_dropdown_2',
  entity_additional_dropdown_3: 'entity_additional_dropdown_3',
  entity_additional_dropdown_4: 'entity_additional_dropdown_4',
  entity_additional_multi_dropdown_1: 'entity_additional_multi_dropdown_1',
  entity_additional_multi_dropdown_2: 'entity_additional_multi_dropdown_2',
  entity_additional_multi_dropdown_3: 'entity_additional_multi_dropdown_3',
  entity_additional_multi_dropdown_4: 'entity_additional_multi_dropdown_4',
  entity_additional_text_1: 'entity_additional_text_1',
  entity_additional_text_2: 'entity_additional_text_2',
  entity_additional_text_3: 'entity_additional_text_3',
  entity_additional_text_4: 'entity_additional_text_4',
  entity_additional_textarea_1: 'entity_additional_textarea_1',
  entity_additional_textarea_2: 'entity_additional_textarea_2',
  entity_additional_textarea_3: 'entity_additional_textarea_3',
  entity_additional_textarea_4: 'entity_additional_textarea_4',
  entityRating: 'entity_rating',
  entityTier: 'Tier',
  entity_tier: 'entity_tier',
  user_department_level1: 'user_department_level1',
  user_department_level2: 'user_department_level2',
  user_department_level3: 'user_department_level3',
  user_department_level4: 'user_department_level4'
};
export const departmentDropDown = {
  entityDropdown: 'entityDropdown',
  sectorDropdown: 'sectorDropdown',
  typeDropdown: 'typeDropdown',
  managerDropdown: 'managerDropdown',
  megaProcessDropdown: 'megaProcessDropdown',
  majorProcessDropdown: 'majorProcessDropdown',
  countryDropdown: 'countryDropdown',
  stateDropdown: 'stateDropdown',
  regionDropdown: 'regionDropdown',
  rateingDropdown: 'rateingDropdown',
  entityAdditionalDropdown1: 'entityAdditionalDropdown1',
  entityAdditionalDropdown2: 'entityAdditionalDropdown2',
  entityAdditionalDropdown3: 'entityAdditionalDropdown3',
  entityAdditionalDropdown4: 'entityAdditionalDropdown4',
  entityAdditionalMultiDropdown1: 'entityAdditionalMultiDropdown1',
  entityAdditionalMultiDropdown2: 'entityAdditionalMultiDropdown2',
  entityAdditionalMultiDropdown3: 'entityAdditionalMultiDropdown3',
  entityAdditionalMultiDropdown4: 'entityAdditionalMultiDropdown4',
  departmentAdditionalMultiDropdown1: 'departmentAdditionalMultiDropdown1',
  departmentAdditionalMultiDropdown2: 'departmentAdditionalMultiDropdown2',
  departmentAdditionalMultiDropdown3: 'departmentAdditionalMultiDropdown3',
  departmentAdditionalMultiDropdown4: 'departmentAdditionalMultiDropdown4',
  departmentAdditionalDropdown1: 'departmentAdditionalDropdown1',
  departmentAdditionalDropdown2: 'departmentAdditionalDropdown2',
  departmentAdditionalDropdown3: 'departmentAdditionalDropdown3',
  departmentAdditionalDropdown4: 'departmentAdditionalDropdown4',
  departmentAdditionalUser1: 'departmentAdditionalUser1',
  departmentAdditionalUser2: 'departmentAdditionalUser2',
  departmentAdditionalUser3: 'departmentAdditionalUser3',
  departmentAdditionalUser4: 'departmentAdditionalUser4',
  entityAdditionalUser1: 'entityAdditionalUser1',
  entityAdditionalUser2: 'entityAdditionalUser2',
  entityAdditionalUser3: 'entityAdditionalUser3',
  entityAdditionalUser4: 'entityAdditionalUser4',
  entityTier: 'entityTier',
  tier: 'tier',
  designationDropdown: 'designationDropdown',
  designationDropdown2: 'designationDropdown2',
  designationDropdown3: 'designationDropdown3',
  designationDropdown4: 'designationDropdown4',
  userDepartmentLevel1: 'userDepartmentLevel1',
  userDepartmentLevel2: 'userDepartmentLevel2',
  userDepartmentLevel3: 'userDepartmentLevel3',
  userDepartmentLevel4: 'userDepartmentLevel4',
  parentLevelDropdown: 'parentLevelDropdown',
  functionDropdown: 'functionDropdown',
  scoringModel: 'scoringModel'
};

export const functionLabels =  {
  functionManager: 'manager',
  region: 'region',
  description: 'description',
  entity: 'name',
  functionLabel: 'entity',
  sector: 'sector',
  type: 'function_type',
  functionType: 'Function Type',
  manager: 'function_managers',
  megaProcess: 'mega_process',
  majorProcess: 'major_process',
  megaProcessSpace: 'Mega Process',
  majorProcessSpace: 'Major Process',
  addressLineOne: 'ADDRESS LINE1',
  addressLineTwo: 'ADDRESS LINE2',
  country: 'country',
  state: 'state',
  city: 'city',
  zipCode: 'ZIP CODE',
  alternateContact: 'Alternative Contact Number',
  contact: 'contact_number',
  email: 'email',
  addressLineOneForm: 'address_line1',
  addressLineTwoForm: 'address_line2',
  zipCodeForm: 'zip_code',
  alternateContactForm: 'alternate_contact',
  function_additional_user_1: 'function_additional_user_1',
  function_additional_user_2: 'function_additional_user_2',
  function_additional_user_3: 'function_additional_user_3',
  function_additional_user_4: 'function_additional_user_4',
  function_additional_dropdown_1: 'function_additional_dropdown_1',
  function_additional_dropdown_2: 'function_additional_dropdown_2',
  function_additional_dropdown_3: 'function_additional_dropdown_3',
  function_additional_dropdown_4: 'function_additional_dropdown_4',
  function_additional_multi_dropdown_1: 'function_additional_multi_dropdown_1',
  function_additional_multi_dropdown_2: 'function_additional_multi_dropdown_2',
  function_additional_multi_dropdown_3: 'function_additional_multi_dropdown_3',
  function_additional_multi_dropdown_4: 'function_additional_multi_dropdown_4',
  function_additional_text_1: 'function_additional_text_1',
  function_additional_text_2: 'function_additional_text_2',
  function_additional_text_3: 'function_additional_text_3',
  function_additional_text_4: 'function_additional_text_4',
  function_additional_textarea_1: 'function_additional_textarea_1',
  function_additional_textarea_2: 'function_additional_textarea_2',
  function_additional_textarea_3: 'function_additional_textarea_3',
  function_additional_textarea_4: 'function_additional_textarea_4',
  functionRating: 'function_rating',
  tier: 'tier',
  user_department_level1: 'user_department_level1',
  user_department_level2: 'user_department_level2',
  user_department_level3: 'user_department_level3',
  user_department_level4: 'user_department_level4'
};

export const functionDropDown = {
  entityDropdown: 'entityDropdown',
  sectorDropdown: 'sectorDropdown',
  typeDropdown: 'typeDropdown',
  managerDropdown: 'managerDropdown',
  megaProcessDropdown: 'megaProcessDropdown',
  majorProcessDropdown: 'majorProcessDropdown',
  countryDropdown: 'countryDropdown',
  stateDropdown: 'stateDropdown',
  regionDropdown: 'regionDropdown',
  rateingDropdown: 'rateingDropdown',
  functionAdditionalDropdown1: 'functionAdditionalDropdown1',
  functionAdditionalDropdown2: 'functionAdditionalDropdown2',
  functionAdditionalDropdown3: 'functionAdditionalDropdown3',
  functionAdditionalDropdown4: 'functionAdditionalDropdown4',
  functionAdditionalMultiDropdown1: 'functionAdditionalMultiDropdown1',
  functionAdditionalMultiDropdown2: 'functionAdditionalMultiDropdown2',
  functionAdditionalMultiDropdown3: 'functionAdditionalMultiDropdown3',
  functionAdditionalMultiDropdown4: 'functionAdditionalMultiDropdown4',
  departmentAdditionalMultiDropdown1: 'departmentAdditionalMultiDropdown1',
  departmentAdditionalMultiDropdown2: 'departmentAdditionalMultiDropdown2',
  departmentAdditionalMultiDropdown3: 'departmentAdditionalMultiDropdown3',
  departmentAdditionalMultiDropdown4: 'departmentAdditionalMultiDropdown4',
  departmentAdditionalDropdown1: 'departmentAdditionalDropdown1',
  departmentAdditionalDropdown2: 'departmentAdditionalDropdown2',
  departmentAdditionalDropdown3: 'departmentAdditionalDropdown3',
  departmentAdditionalDropdown4: 'departmentAdditionalDropdown4',
  functionAdditionalUser1: 'functionAdditionalUser1',
  functionAdditionalUser2: 'functionAdditionalUser2',
  functionAdditionalUser3: 'functionAdditionalUser3',
  functionAdditionalUser4: 'functionAdditionalUser4',
  tier: 'tier',
  designationDropdown: 'designationDropdown',
  userDepartmentLevel1: 'userDepartmentLevel1',
  userDepartmentLevel2: 'userDepartmentLevel2',
  userDepartmentLevel3: 'userDepartmentLevel3',
  userDepartmentLevel4: 'userDepartmentLevel4',
  parentLevelDropdown: 'parentLevelDropdown'
};

export const scoringFactors = [
  {
    'title': 'Department Scoring',
    'weightage': 100,
    overallScoring: [
      {
        'id': 430,
        'name': 'Low',
        'weightage': 1,
        'color': '#54c854',
        'range': [
          1,
          1.499999999
        ],
        active: true
      },
      {
        'id': 431,
        'name': 'Medium',
        'weightage': 2,
        'color': '#f9b859',
        'range': [
          1.5,
          2.499999999
        ]
      },
      {
        'id': 1457,
        'name': 'High',
        'weightage': 3,
        'color': '#df2020',
        'range': [
          2.5,
          3.499999999
        ]
      }
    ],
    severities: [
      {
        'weightage': 50,
        language: 'BUSINESS RISK',
        'severity': [
          {
            'id': 430,
            'name': 'Low',
            'weightage': 1,
            'color': '#54c854',
            'range': [
              1,
              1.499999999
            ]
          },
          {
            'id': 431,
            'name': 'Medium',
            'weightage': 2,
            'color': '#f9b859',
            'range': [
              1.5,
              2.499999999
            ]
          },
          {
            'id': 1457,
            'name': 'High',
            'weightage': 3,
            'color': '#df2020',
            'range': [
              2.5,
              3.499999999
            ]
          }
        ]
      },
      {
        'weightage': 20,
        language: 'COMPANY’S MATERIALITY',
        'severity': [
          {
            'id': 430,
            'name': 'Low',
            'weightage': 1,
            'color': '#54c854',
            'range': [
              1,
              1.499999999
            ]
          },
          {
            'id': 431,
            'name': 'Medium',
            'weightage': 2,
            'color': '#f9b859',
            'range': [
              1.5,
              2.499999999
            ]
          },
          {
            'id': 1457,
            'name': 'High',
            'weightage': 3,
            'color': '#df2020',
            'range': [
              2.5,
              3.499999999
            ]
          }
        ]
      },
      {
        'weightage': 10,
        language: 'COMPLEXITY OF THE AUDITABLE AREA',
        'severity': [
          {
            'id': 430,
            'name': 'Low',
            'weightage': 1,
            'color': '#54c854',
            'range': [
              1,
              1.499999999
            ]
          },
          {
            'id': 431,
            'name': 'Medium',
            'weightage': 2,
            'color': '#f9b859',
            'range': [
              1.5,
              2.499999999
            ]
          },
          {
            'id': 1457,
            'name': 'High',
            'weightage': 3,
            'color': '#df2020',
            'range': [
              2.5,
              3.499999999
            ]
          }
        ]
      },
      {
        'weightage': 10,
        language: 'EXTENT OF SYSTEM',
        'severity': [
          {
            'id': 430,
            'name': 'Low',
            'weightage': 1,
            'color': '#54c854',
            'range': [
              1,
              1.499999999
            ]
          },
          {
            'id': 431,
            'name': 'Medium',
            'weightage': 2,
            'color': '#f9b859',
            'range': [
              1.5,
              2.499999999
            ]
          },
          {
            'id': 1457,
            'name': 'High',
            'weightage': 3,
            'color': '#df2020',
            'range': [
              2.5,
              3.499999999
            ]
          }
        ]
      },
      {
        'weightage': 5,
        language: 'TIME SINCE LAST AUDITS',
        'severity': [
          {
            'id': 430,
            'name': '1-2 Years',
            'weightage': 1,
            'color': '#54c854',
            'range': [
              1,
              1.499999999
            ]
          },
          {
            'id': 431,
            'name': '2-3 Years',
            'weightage': 2,
            'color': '#f9b859',
            'range': [
              1.5,
              2.499999999
            ]
          },
          {
            'id': 1457,
            'name': '3+ Years',
            'weightage': 3,
            'color': '#df2020',
            'range': [
              2.5,
              3.499999999
            ]
          }
        ]
      },
      {
        'weightage': 5,
        language: 'REPORTED FRAUDS',
        'severity': [
          {
            'id': 430,
            'name': 'No Incidents',
            'weightage': 1,
            'color': '#54c854',
            'range': [
              1,
              1.499999999
            ]
          },
          {
            'id': 431,
            'name': 'Occasional',
            'weightage': 2,
            'color': '#f9b859',
            'range': [
              1.5,
              2.499999999
            ]
          },
          {
            'id': 1457,
            'name': 'Frequent',
            'weightage': 3,
            'color': '#df2020',
            'range': [
              2.5,
              3.499999999
            ]
          }
        ]
      }
    ]
  }
];
export const departmentGrid = [{
  sections: 12,
  region: 'Middle East',
  'department': 'development',
  'description': 'for developers',
  'entity': {
    'object_id': '3387559d-ed26-4810-ab80-16d90cfc070f',
    'id': 48,
    'entity_id': 'E-048',
    'name': 'Beinex Qatar',
    'name_v1': 'Beinex Qatar',
    'entity_type': 'QA',
    'entity_type_v1': 'QA',
    'address_line1': '',
    'address_line1_v1': '',
    'address_line2': '',
    'address_line2_v1': '',
    'state': 3181,
    'country': 179,
    'zip_code': '',
    'zip_code_v1': '',
    'contact_number': '',
    'contact_number_v1': '',
    'entity_manager': 1,
    'region': 21,
    'entity_size': 688,
    'is_default': false,
    'entity_checked': false,
    'permission_status': true
  },
  'sector': [
    {
      'sector_id': 'S-014',
      'id': 14,
      'name': 'Sector 1',
      'sector_type': '',
      'sector_manager': {
        'id': 1,
        'name': 'John  Doe'
      },
      'object_id': 'e19b76eb-1f2b-4a9a-9cf1-cf899b49534d',
      'is_default': false,
      'permission_status': true
    }
  ],
  'type': [
    {
      'id': 483,
      'color': '#E9F0F7',
      'parent': null,
      'percentage': null,
      'created_date': '2021-05-31T07:23:25.114389Z',
      'modified_date': '2022-07-20T14:16:34.894500Z',
      'field': 'risk_type',
      'value': 'Financial',
      'order': 2,
      'is_hide': false,
      'weightage': 0,
      'is_system': false,
      'model': null,
      'description': null,
      'slug': 'none',
      'is_overdue': false,
      'is_default': true,
      'created_by': null,
      'updated_by': null,
      'module': 23,
      'asset_color': 347,
      'permission_status': true
    }
  ],
  'manager': [
    {
      'id': 259,
      'name': 'Francis George',
      'email': 'george.beinex@gmail.com',
      'designation': 'Auditor',
      'profile_pic': 'https://auraa-staging-static.s3.amazonaws.com/static/assets/img/user-512x.png',
      'type': 'user',
      'avatar': 'https://auraa-staging-static.s3.amazonaws.com/static/assets/img/user-512x.png',
      'display': '@FrancisGeorge',
      'user_id': 'EM-259',
      'reporting_to': '',
      'permission_status': true
    }
  ],
  'megaProcess': [
    {
      'id': 773,
      'color': '#e9f0f7',
      'parent': null,
      'percentage': null,
      'created_date': '2021-06-30T09:15:47.088593Z',
      'modified_date': '2022-04-21T15:27:16.844485Z',
      'field': 'mega_process',
      'value': 'Medical Records Maintenance',
      'order': 6,
      'is_hide': false,
      'weightage': 0,
      'is_system': false,
      'model': null,
      'description': null,
      'slug': 'medical-records-maintenance',
      'is_overdue': false,
      'is_default': false,
      'created_by': null,
      'updated_by': null,
      'module': 23,
      'asset_color': 636,
      'permission_status': true
    }
  ],
  'majorProcess': [
    {
      'id': 774,
      'field': 'major_process',
      'value': 'General',
      'parent': 773,
      'module': 23
    }
  ],
  'scoring': [
    {
      'title': 'Department Scoring',
      'weightage': 100,
      'overallScoring': [
        {
          'id': 430,
          'name': 'Low',
          'weightage': 1,
          'color': '#54c854',
          'range': [
            1,
            1.499999999
          ],
          'active': true
        },
        {
          'id': 431,
          'name': 'Medium',
          'weightage': 2,
          'color': '#f9b859',
          'range': [
            1.5,
            2.499999999
          ]
        },
        {
          'id': 1457,
          'name': 'High',
          'weightage': 3,
          'color': '#df2020',
          'range': [
            2.5,
            3.499999999
          ]
        }
      ],
      'severities': [
        {
          'weightage': 50,
          'language': 'BUSINESS RISK',
          'severity': [
            {
              'id': 430,
              'name': 'Low',
              'weightage': 1,
              'color': '#54c854',
              'range': [
                1,
                1.499999999
              ],
              'active': false
            },
            {
              'id': 431,
              'name': 'Medium',
              'weightage': 2,
              'color': '#f9b859',
              'range': [
                1.5,
                2.499999999
              ],
              'active': true
            },
            {
              'id': 1457,
              'name': 'High',
              'weightage': 3,
              'color': '#df2020',
              'range': [
                2.5,
                3.499999999
              ],
              'active': false
            }
          ]
        },
        {
          'weightage': 20,
          'language': 'COMPANY’S MATERIALITY',
          'severity': [
            {
              'id': 430,
              'name': 'Low',
              'weightage': 1,
              'color': '#54c854',
              'range': [
                1,
                1.499999999
              ],
              'active': true
            },
            {
              'id': 431,
              'name': 'Medium',
              'weightage': 2,
              'color': '#f9b859',
              'range': [
                1.5,
                2.499999999
              ],
              'active': false
            },
            {
              'id': 1457,
              'name': 'High',
              'weightage': 3,
              'color': '#df2020',
              'range': [
                2.5,
                3.499999999
              ],
              'active': false
            }
          ]
        },
        {
          'weightage': 10,
          'language': 'COMPLEXITY OF THE AUDITABLE AREA',
          'severity': [
            {
              'id': 430,
              'name': 'Low',
              'weightage': 1,
              'color': '#54c854',
              'range': [
                1,
                1.499999999
              ],
              'active': true
            },
            {
              'id': 431,
              'name': 'Medium',
              'weightage': 2,
              'color': '#f9b859',
              'range': [
                1.5,
                2.499999999
              ],
              'active': false
            },
            {
              'id': 1457,
              'name': 'High',
              'weightage': 3,
              'color': '#df2020',
              'range': [
                2.5,
                3.499999999
              ],
              'active': false
            }
          ]
        },
        {
          'weightage': 10,
          'language': 'EXTENT OF SYSTEM',
          'severity': [
            {
              'id': 430,
              'name': 'Low',
              'weightage': 1,
              'color': '#54c854',
              'range': [
                1,
                1.499999999
              ],
              'active': false
            },
            {
              'id': 431,
              'name': 'Medium',
              'weightage': 2,
              'color': '#f9b859',
              'range': [
                1.5,
                2.499999999
              ],
              'active': true
            },
            {
              'id': 1457,
              'name': 'High',
              'weightage': 3,
              'color': '#df2020',
              'range': [
                2.5,
                3.499999999
              ],
              'active': false
            }
          ]
        },
        {
          'weightage': 5,
          'language': 'TIME SINCE LAST AUDITS',
          'severity': [
            {
              'id': 430,
              'name': '1-2 Years',
              'weightage': 1,
              'color': '#54c854',
              'range': [
                1,
                1.499999999
              ],
              'active': true
            },
            {
              'id': 431,
              'name': '2-3 Years',
              'weightage': 2,
              'color': '#f9b859',
              'range': [
                1.5,
                2.499999999
              ],
              'active': false
            },
            {
              'id': 1457,
              'name': '3+ Years',
              'weightage': 3,
              'color': '#df2020',
              'range': [
                2.5,
                3.499999999
              ],
              'active': false
            }
          ]
        },
        {
          'weightage': 5,
          'language': 'REPORTED FRAUDS',
          'severity': [
            {
              'id': 430,
              'name': 'No Incidents',
              'weightage': 1,
              'color': '#54c854',
              'range': [
                1,
                1.499999999
              ],
              'active': false
            },
            {
              'id': 431,
              'name': 'Occasional',
              'weightage': 2,
              'color': '#f9b859',
              'range': [
                1.5,
                2.499999999
              ],
              'active': true
            },
            {
              'id': 1457,
              'name': 'Frequent',
              'weightage': 3,
              'color': '#df2020',
              'range': [
                2.5,
                3.499999999
              ],
              'active': false
            }
          ]
        }
      ]
    }
  ],
  'dept': 6,
  'risk': 16,
  'audit': 14,
  'id': 1,
  'enId': 'DP-1',
  'entity_rating': {
    'color': '#66bb66',
    'value': 'Low'
  }
}];
export const entityGrid = [{
  enId: 'En-1',
  'entity': 'beinex',
  'description': 'this is used for developers',
  'region': 'indian',
  'sector': [
    {
      'sector_id': 'S-017',
      'id': 17,
      'name': 'setor 4',
      'sector_type': '',
      'sector_manager': {
        'id': 1,
        'name': 'John  Doe'
      },
      'object_id': 'f2bc4570-756e-4555-b2ce-b72677a180a4',
      'is_default': true,
      'permission_status': true
    }
  ],
  'type': [
    {
      'id': 404,
      'color': '#ff537c',
      'parent': null,
      'percentage': null,
      'created_date': '2021-05-28T08:17:33.594179Z',
      'modified_date': '2022-04-21T15:27:15.857279Z',
      'field': 'risk_type',
      'value': 'Operations',
      'order': 4,
      'is_hide': false,
      'weightage': 0,
      'is_system': false,
      'model': null,
      'description': null,
      'slug': 'operations',
      'is_overdue': false,
      'is_default': false,
      'created_by': null,
      'updated_by': null,
      'module': 23,
      'asset_color': 277,
      'permission_status': true
    }
  ],
  'manager': [
    {
      'id': 259,
      'name': 'Francis George',
      'email': 'george.beinex@gmail.com',
      'designation': 'Auditor',
      'profile_pic': 'https://auraa-staging-static.s3.amazonaws.com/static/assets/img/user-512x.png',
      'type': 'user',
      'avatar': 'https://auraa-staging-static.s3.amazonaws.com/static/assets/img/user-512x.png',
      'display': '@FrancisGeorge',
      'user_id': 'EM-259',
      'reporting_to': '',
      'permission_status': true
    }
  ],
  'megaProcess': [
    {
      'id': 682,
      'color': '#910ecd',
      'parent': null,
      'percentage': null,
      'created_date': '2021-06-22T04:02:59.258925Z',
      'modified_date': '2022-04-21T15:27:16.832841Z',
      'field': 'mega_process',
      'value': 'Commercial',
      'order': 1,
      'is_hide': false,
      'weightage': 0,
      'is_system': false,
      'model': null,
      'description': null,
      'slug': 'commercial',
      'is_overdue': false,
      'is_default': false,
      'created_by': null,
      'updated_by': null,
      'module': 23,
      'asset_color': null,
      'permission_status': true
    }
  ],
  'majorProcess': [
    {
      'id': 686,
      'field': 'major_process',
      'value': 'Revenue Optimization',
      'parent': 682,
      'module': 23
    }
  ],
  'addressLineOne': '',
  'addressLineTwo': '',
  'country': '',
  'state': '',
  'city': '',
  'zipCode': '',
  'alternateContact': '',
  'contact': '',
  'email': '',
  'dept': 6,
  'risk': 16,
  'audit': 14,
  'id': 1,
  'entity_rating': {
    'color': '#66bb66',
    'value': 'Low'
  }
}];
export const dependency = {
  audit: 'audit',
  risk: 'risk',
  department: 'department',
  section: 'section',
  function: 'function',
  network: 'network',
  auditDepartment: 'auditDepartment',
  riskDepartment: 'riskDepartment',
  auditSection: 'auditSection',
  riskSection: 'riskSection',
  functionDepartment: 'functionDepartment',
  sections: 'sections',
  entity: 'entity',
  functions: 'functions'
};
export const dependencyTree = {
  'object_id': 'c965623f-dbf7-4a9b-bf21-d2c1a9b2ddd1',
  'name': 'Bio Medical Waste management',
  'type': 'audit',
  'color': '#316395',
  'children': [
    {
      'name': 'Risks',
      'color': '#DD4477',
      'children': [
        {
          'object_id': '20d9c536-a3fe-4654-877e-21cdd927f65e',
          'name': 'Insufficient monitoring and proper reconciliation of bank accounts',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '1c5bb070-1725-4b6d-bdf5-22effbddc1db',
          'name': 'Non Compliance to Statute',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'bed9cde5-c00a-49bb-9c02-2bfb2c6e536d',
          'name': 'Non compliance - Segregation and Containers',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'aadb4d91-94b3-4f8d-aa67-ec9e908661b6',
          'name': 'Non compliance - Transporting Bio-Medical Waste',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'd060e857-aa94-44c1-b424-36df28069248',
          'name': 'Non Compliance Storing Untreated Bio-Medical Waste',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '6a724524-31e3-48de-b875-b2ef3f10b7b0',
          'name': 'Risk 26',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'f226e434-b854-49b9-93c5-91458f85623a',
          'name': 'risk creation',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'eb9e0126-b08f-4170-91ad-420ff29bf39c',
          'name': 'Risk1',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'c98d62dc-d1bb-48df-b227-4f248a4eb609',
          'name': 'Risk permission 88',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'b33e5dc4-7471-487e-974f-0c715fe064f4',
          'name': 'Copy( Risk 12 )',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '4ab493f0-aab8-4820-a163-215181cd0f14',
          'name': 'test risk 24-02',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '99535a6a-2901-4a14-a6c7-ec93859a446c',
          'name': 'test demo',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '7af050b3-9a0c-4126-a364-48b9463c595f',
          'name': 'risk788',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'd1de22ff-e763-4475-a806-be013cf7ee69',
          'name': 'Copy( Risk )',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '1715179e-3111-4a4f-9c81-86facb3741f7',
          'name': 'demo edit1',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'f71d1f96-ee9e-4153-9a2c-6345e750029a',
          'name': 'risk test12 ERP',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '907066ce-c024-4732-b37b-040659e1959a',
          'name': 'test risk edited',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '4f430b34-4bdf-4fe7-9432-b4fb68952945',
          'name': 'QA',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '605278a9-671d-4ccd-b183-c3fc45f4fc7d',
          'name': 'Risk Management Strategy The risk management strategy is one of the key outputs of the risk framing component of the NIST risk management process. Typically developed at the organization level, the risk management strategy specifies procedures and methodologies with which mission and business and information system risk managers perform risk assessment, risk response, and risk monitoring activities. As',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'cfa8d757-990d-46ae-831a-a753c05f53db',
          'name': 'rgf',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '1c532563-3a66-49b3-86fb-f5e05965aa01',
          'name': 'Financial risk011',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '4207f606-6565-44d9-864d-8aa6a06fd2c1',
          'name': 'test risk__1',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '5c90de49-0cea-4a72-bac4-ceb87712ec0a',
          'name': 'test risk',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'ff831972-81da-4ae4-866e-32edf8a3301e',
          'name': 'Financial issue',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'da4c7962-5dc9-42e3-85b6-ec6faa211807',
          'name': 'Financial issue',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '865dc1d0-3fae-4982-add5-1e0f4f4d890c',
          'name': 'new risk testing 10052022',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'bcc90c34-1bad-408d-8892-ceb2f68a8f2a',
          'name': 'njk',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '7e6fd4bf-c958-457d-92a2-ae04cd346b35',
          'name': 'res',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': '40015ca8-7303-41af-98ba-5bd013237e91',
          'name': 'QA',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'ffc7187d-6d9a-4374-8a0a-65992a8a90ef',
          'name': 'new risk testing 1805',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'c78c9ead-8d41-4059-a534-d54304f3adc5',
          'name': 'risk_draft_180522__1',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        },
        {
          'object_id': 'e1245e1e-6642-469c-a76e-c887f91c01d1',
          'name': 'ded',
          'color': '#DD4477',
          'type': 'risk',
          'show_count': false,
          'permission_status': true
        }
      ],
      'count': 32,
      'show_count': true
    },
    {
      'name': 'Observations',
      'color': '#E67300',
      'children': [],
      'count': 0,
      'show_count': true
    },
    {
      'name': 'Controls',
      'color': '#DD4477',
      'children': [
        {
          'object_id': '6c638b7e-0539-4a00-b714-fd7290a51968',
          'name': 'EBITDA margin',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': '9c580e13-991d-47f2-acc3-98505d900fc1',
          'name': 'Reconciliation process is in place by the Finance Department',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': '5feaad87-05e5-4b6f-8064-923e9da7319d',
          'name': 'Segregation and Containers',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': '8ee2604e-d7ae-41b7-821d-14fe79441239',
          'name': 'Transporting Bio-Medical Waste',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': 'f92bdfa7-06ef-481d-8007-17b4ba971d7b',
          'name': 'Storing Untreated Bio-Medical Waste',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': 'd0a1dc6f-6b2f-4dcf-826b-ad9b06eb289e',
          'name': 'Copy( Control 9 )',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': 'b16c631a-1b5d-4e70-876a-cd0f43e2e822',
          'name': 'Control Test',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': '880b2f67-27e6-4adb-8f0e-75e798398401',
          'name': 'Copy( Financial controls are the procedures, policies, and means by which an organization monitors and controls the direction, allocation, and usage of its financial resources. Financial controls are at the very core of resource management and operational efficiency in any organization. )',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': 'f0d8785e-845a-418f-8d22-9d0f807fc1cf',
          'name': 'CONTROL1234567',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': '876f7024-3555-49e5-b323-cf8fcaf2fc60',
          'name': 'Control Feb 25',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': 'dc7cc10f-5a19-475b-b5b3-445b15b03c5d',
          'name': 'teest',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': '06d3959d-f84b-4583-aaa4-c842976add30',
          'name': 'FINANCIAL CONTORL28-02This type of financial risk arises out of legal constraints such as lawsuits. Whenever a company needs to face financial losses out of legal proceedings, it is a legal risk.',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': 'f97b5b8a-d71a-40a1-a254-67a7de7e624e',
          'name': 'control22',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': '40aae64b-2392-4842-bf62-e25b0f9f102f',
          'name': 'Control test 01',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': 'b9dbd001-f451-4322-aed0-81831fa8f011',
          'name': 'test control new',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': 'a329ba87-c0d8-4a6b-a1d1-b1e419c7be16',
          'name': 'Control test email notifications',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': '072b2b12-db56-44e9-86d8-b0a9f9444561',
          'name': 'testAssessmentControl',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': '072b2b12-db56-44e9-86d8-b0a9f9444561',
          'name': 'testAssessmentControl',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': '072b2b12-db56-44e9-86d8-b0a9f9444561',
          'name': 'testAssessmentControl',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        },
        {
          'object_id': '072b2b12-db56-44e9-86d8-b0a9f9444561',
          'name': 'testAssessmentControl',
          'color': '#DD4477',
          'show_count': false,
          'type': 'control',
          'permission_status': null
        }
      ],
      'count': 17,
      'show_count': true
    },
    {
      'name': 'Recommendations',
      'color': '#329262',
      'children': [],
      'count': 0,
      'show_count': true
    },
    {
      'name': 'Actions',
      'color': '#5574A6',
      'children': [],
      'count': 0,
      'show_count': true
    }
  ],
  'show_count': false
};

export enum ConfigurableEntity {
  yes = 'Yes',
  fieldName = 'field_name',
  cancel = 'cancel',
  auditReporting = 'audit-reporting',
  foreignKey = 'ForeignKey',
  tableId = 'table_id',
  auditReportingAction = 'audit-reporting-action',
  department = 'department ',
  actionDepartment = 'action_department',
  auditPlanning = 'audit-planning',
  color = '#E9F0F7',
  auditRecommendation = 'audit-reporting-recommendation',
  recommendationDepartment = 'recommendation_department',
  futureSnapshot = '_futureSnapshot',
  configurable = 'configurable',
  status = 'status',
  auditStatus = 'audit_status',
  field = 'field',
  issueStatus = 'issue_status',
  issueRating = 'issue_rating',
  managentStatus = 'management_status',
  observationStatus = 'observation_status',
  statusManagement = 'Management Status',
  slugDepartment = 'department-details',
  slugEntity = 'entity-details',
  slugFunction = 'functions',
  slugSection = 'section',
  slug = 'slug',
  slugEntityName = 'entities',
  name = 'name',
  slugSectionName = 'sections'
}

export const scoringModelEntityManagement = {
  department: 'department',
  entity: 'entity-details',
  section: 'section'
};
export const subTitleScoring = {
  department: 'Department Rating Factors',
  entity: 'Entity Rating Factors',
  section: 'Section Rating Factors',
  departmentDynamic: 'Department Dynamic Rating',
  entityDynamic: 'Dynamic Entity Rating',
  departmentRating: 'Department Rating',
  entityScoring: 'Entity Dynamic Rating',
  departmentRatingFactor: 'Department Rating Factors',
  entityRatingFactor: 'Entity Rating Factors',
  entityRating: 'Entity Rating',
  entityModal: 'Entity Rating Calculation',
  departmentModal: 'Department Rating Calculation',
  sectionRatingFactor: 'Section Rating Factors',
  sectionScoring: 'Section Dynamic Rating',
  sectionRating: 'Section Rating',
  sectionModal: 'Section Rating Calculation',
  departmentScoringModal: 'Department Scoring Models'
};

export const userDepartment = {
  'dynamic_data': {
    'field_name': 'department_Levels',
    'language': {
      'ar': 'Department Levels',
      'en-us': 'Department Levels'
    },
    'configuration': {
      'show': true,
      'mandatory': false,
      'description': false
    },
    'permission_status': true
  },
  'field_name': 'Department Levels',
  'configurable': 'Yes',
  'slug': 'department',
  'field': 'department_level',
  'data_type': 'ForeignKey'
};

export const userPermissionSlug = {
  entities: 'entities',
  functions: 'functions',
  sections: 'sections',
  departments: 'departments'
};
