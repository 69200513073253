export const environment = {
  production: true,
  version: require('../../package.json').version,
  domain: 'akigroup.auraa.com',
  cookieDomain: 'akigroup.auraa.com',
  baseUrl:  'https://akigroup.auraa.com',
  baseVersion: '/v1',
  apiVersion: '/api/v5/',
  protocol: 'https://',
  socketProtocol:  'wss://',
  clientName: 'akiAccess',
  akiAccess: true,
  console: false,
  amlakAccess: false,
  saml: true,
  clientEnv: true,
  retalAccess: false,
  boodaiAcess:false,
  alhabtoorAccess:false,
  galadariAccess:false
};
