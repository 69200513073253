import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import { AdminUserService } from '../service/control-panel/admin-user.service';

@Injectable({
  providedIn: 'root'
})

export class ResolveGuard implements Resolve<any> {
  constructor(private _admin: AdminUserService) {}
   resolve() {
     return this._admin.getSidebarPermission('', '');
   }
}
