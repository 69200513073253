import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public timeOut: number = 5000;

  constructor(
    private _toaster: ToastrService,
    private _translate: TranslateService
  ) { }

  success(text: string = '') {
    this._toaster.success(this._translate.instant(text), '', {
      timeOut: this.timeOut,
      closeButton: true
    });
  }

  error(text: string = '') {
    this._toaster.error(this._translate.instant(text), '', {
      timeOut: this.timeOut,
      closeButton: true
    });
  }

  warning(text: string = '') {
    this._toaster.warning(this._translate.instant(text), '', {
      timeOut: this.timeOut,
      closeButton: true
    });
  }

  networkError(text: string = 'Currently unable to handle the request. HTTP Error 500') {
    this._toaster.error(this._translate.instant(text), '', {
      timeOut: this.timeOut,
      closeButton: true
    });
  }

  /**
   * @description To set a toaster for stay until another action occur
   * @author Siji Johnson
   * @date 10/04/2023
   */

  info(text: string) {
    const message = this._toaster.info(this._translate.instant(text), '', {
      closeButton: true,
      disableTimeOut: true
    });
    return message;
  }

  remove(id: number) {
    this._toaster.remove(id);
  }
}
