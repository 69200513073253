import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable()
export class HttpUrlInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let url = request.url;
    if (!url.includes('.s3.amazonaws.com')) {
      const timestamp = new Date().getTime();
      // Check whether the url ends with &
      if (url[url.length - 1] === '&') {
        url = `${url}timeStamp=${timestamp}`;
      } else if (url.includes('?')) {
        // Check if url already have query param
        url = `${url}&timeStamp=${timestamp}`;
      } else {
        // If no query params added
        url = `${url}?timeStamp=${timestamp}`;
      }
    }
    const cloned = request.clone({
      url: url,
      withCredentials: true
    });
    return next.handle(cloned);
  }
}
