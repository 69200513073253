export const toastMessages = {
  deleted: 'Deleted',
  success: 'Success',
  updated: 'Updated',
  created: 'Created',
  duplicated: 'Duplicate created successfully',
  formError: 'There are items that require your attention',
  nextPageFormError: 'There are items that require your attention in the session of Design Assessment',
  validationError: 'Please fill all the mandatory fields',
  validationErrorAllField: 'Please fill all the fields',
  invalidDate: 'Please select valid date',
  selectDate: 'Please select a date',
  selectStatus: 'Please select status',
  invalidTime: 'Please enter valid time',
  invalidEmail: 'Invalid email',
  noLocalPath: 'No local path is added',
  typeSomething: 'Please add a comment',
  typeSomeResponse: 'Please add a response',
  pleaseSelectOneItem: 'Please select at least one item',
  duplicateTableRelation: 'Duplicate table, Please edit the relation',
  fieldDisabled: 'This field is disabled',
  fieldNameRequired: 'Field Name is required.',
  noFieldFoundInExpression: 'No field is found in this expression',
  noChartSelected: 'Select a chart type',
  noDashboardTitle: 'Please provide dashboard title',
  noGraphCreated: 'Please add at least one chart',
  warningForGoBack: 'Changes you made may not be saved.',
  invalidExpression: 'Please enter a valid expression',
  noExpression: 'Expression field cannot be empty.',
  measureNotSelected: 'Please select atleast one dimension same as the measure',
  diemnsionTwoSelected: 'Please select two dimensions and a measure',
  diemnsionOneSelected: 'Please select one dimension and a measure',
  selectExistingTemplate: 'Please select an exisiting template.',
  riskNotTagged: 'This risk is not tagged to any audit. Please tag an audit to continue.',
  controlNotTagged: 'This control is not tagged to any audit. Please tag an audit to continue.',
  selectSameSlug: 'Please select same module',
  selectMeasureOrDimension: 'Please select measure or dimension first',
  expressionNullError: 'Please enter expression.',
  fieldNameExists: 'The field name can\'t be duplicate or same as the calculation functions',
  duplicateValue: 'This field already exist',
  limitExceeds: 'The maximum length of this field is 70 characters',
  emptyField: 'You can\'t save without a value',
  duplicateCustomValue: 'duplicate custom  field exist',
  limitExceed: 'The maximum length of custom field is 70 characters',
  savCustomField: 'please save the custom fields',
  checkCustom: 'please check  custom fields validation and fill all the mandatory fields',
  noSpecialCharacter: 'You have entered an invalid value',
  permissionRevokedDownload: 'You are not allowed to download',
  browsePermissionMessage: 'You are not allowed to view this record',
  noSignerSelected: 'Please select the signer',
  onlyImage: 'Please select (png,jpg,jpeg) file',
  permissionTagAudit: 'You are not authorized to Tag in Audit Planning',
  permissionTagRiskProgram: 'You are not authorized to Tag in Risk Program',
  permissionUnTagAudit: 'You are not authorized to UnTag in Audit Planning',
  permissionUnTagRiskProgram: 'You are not authorized to UnTag in Risk Program',
  reviewerPermission: 'You are not authorized to Review/Reject this audit',
  approverPermission: 'You are not authorized to Approve/Reject this audit',
  approverViewPermission: 'You are not authorized to view this audit for Approve/Reject ',
  pleaseSelectField: 'Please select a field',
  deleteDocument: 'You are not authorized to delete the document',
  permissionTagObservation: 'You are not authorized to Tag in Observation',
  permissionUnTagObservation: 'You are not authorized to UnTag in Observation',
  approverEditPermission: 'You are not authorized to edit this audit',
  noChange: 'This action cannot be performed as there are no changes',
  negativeValue: 'Negative values are not allowed',
  choiceUpdated: 'Choice list updated',
  timeExceeds: 'Time exceeds 24 hour limit',
  validTime: 'Please enter a valid Time',
  minuteExceeds: 'Time exceeds 59 minute limit',
  eDateAndSignRequiredForAllSigners: 'Please add atleast one date and sign for each signers',
  addOptionForAllSigners: 'Please add option for all the signers selected',
  reminderInvalidTime: 'Time should be atleast 10 minutes ahead of current time',
  multiDocumentNotAllowed: 'You cannot add multiple documents',
  requiredAuditWorkflow: 'Please add the required workflow',
  selectAssignee: 'Please select an assignee',
  auditNotFound: 'Audit not found',
  dashboardEmpty: 'Cards with no data are not allowed',
  selectFolder: 'Please select a folder',
  wrongFormat: 'Switching to selected type is not possible. Please deselect the current item',
  deletedMessage: 'The record you are trying to access has been deleted.',
  statusDeletiondenied: 'You don’t have the permission to change status'
};

export const warningMsgDelete = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this item? You can’t undo this action.',
  subTextApprovalDelete: 'Do you want to delete this record, upon delete this will send for approval',
  subTextAnalytics: 'You are About to Delete Data Source',
  subTextAnalyticsWithData: 'You are About to Delete Data Source with data',
  subTextAudit: 'Are you sure you want to remove this item? You can’t undo this action.',
  buttonOpt_audit: 'Remove',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteRisk = {
  headText: 'You are About to Remove a Risk!',
  subTextAudit: 'Are you sure? You cannot undo this action.',
  buttonOpt_audit: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};

export const warningMsgDeleteControlAudit = {
  headText: 'You are About to Remove a Control!',
  subTextAudit: 'Are you sure? You cannot undo this action.',
  buttonOpt_audit: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};

export const warningDeleteMsg = {
  headText: 'Are You Sure You Want to Delete this Item?',
  subText: 'You can’t undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don\'t delete'
};

export const warningMsgRiskprogramSaveAsDraft = {
  headText: 'Save as Draft?',
  subText: 'Please confirm',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};

export const warningMsgAfterRiskProgramSaveAsDraft = {
  headText: 'Your Draft is Saved',
  subText: 'The risk program has been saved as a draft. Do you want to continue editing?',
  buttonOpt_1: 'Continue editing',
  buttonOpt_2: 'Exit'
};

export const warningDeleteUserMsg = {
  headText: 'Are You Sure You Want to Delete this Item?',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don\'t delete'
};

export const warningAuditPlanDeleteUserMsg = {
  headText: 'You are about to delete an audit plan model!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don\'t delete'
};

export const assessmentWarningMsgDelete = {
  headText: 'You are About to Delete the Template!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don\'t delete'
};

export const warningMsgDeleteDocument = {
  headText: 'You are About to Delete the Document!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};

export const warningMessageClone = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to clone this item? Copy of this item will be created as a new item',
  buttonOpt_1: 'Clone',
  buttonOpt_2: 'Cancel'
};

export const warningMessageWithdraw = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to withdraw this request? Request will be withdrawing from reviewer',
  buttonOpt_1: 'Withdraw',
  buttonOpt_2: 'Cancel'
};

export const warningMessageWidrow = {
  headText: 'Do you want to withdraw the previous request?',
  subText: 'There is a pending edit request which you already created for this same record. You have to withdraw that for a new request.',
  buttonOpt_1: 'Continue',
  buttonOpt_2: 'Cancel'
};
export const warningRequestManagement = {
  headText: 'Proceed to Request Management Response?',
  subText: 'Management responses for the 30 observations will be requested from John Doe..',
  buttonOpt_1: 'Yes, proceed ',
  buttonOpt_2: 'No, don’t proceed'
};
export const warningApproval = {
  headText: 'Deactivate the Approval System?',
  subText: 'While acting, existing edit and delete requests will be discarded. Create requests will move to drafts. Do you want to proceed?',
  buttonOpt_1: 'Yes, proceed ',
  buttonOpt_2: 'No, cancel'
};
export const multipleApproval = {
  headText: 'Deactivate Unanimous Approval?',
  subText: 'Existing records having at least one response will skip the level. Do you want to proceed?',
  buttonOpt_1: 'Yes, proceed ',
  buttonOpt_2: 'No, cancel'
};
export const singleApproval = {
  headText: 'Activate Unanimous Approval?',
  subText: 'Each person should approve before the approval goes through. Pending approvals will require unanimous consent.',
  buttonOpt_1: 'Yes, proceed ',
  buttonOpt_2: 'No, cancel'
};
export const approvalDraft = {
  headText: 'Successful!',
  buttonOpt_1: 'Ok',
  buttonOpt_2: 'View Draft'
};
export const successRequestManagement = {
  headText: 'Successful!',
  subText: 'Bulk management responses are requested from John Doe.',
  buttonOpt_1: 'Ok'
};
export const warningMsgResend = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to reactivate this user? You can’t undo this action.',
  buttonOpt_1: 'Reactivate',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteUser = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this user? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const deleteUserWarning = {
  headText: 'You are About to Delete the user!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don\'t delete'
};

export const warningMsgRemove = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to remove this item? You can’t undo this action.',
  subTextAudit: 'Are you sure you want to remove this item? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeleteScenario = {
  headText: 'You are About to Delete a Scenario',
  subText: 'Are you sure? You can’t undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don\'t delete'
};
export const warningMsgDeleteReport = {
  headText: 'You are About to Delete a Report',
  subText: 'Are you sure? You can’t undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don\'t delete'
};

export const warningMsgDeactivate = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to deactivate this user? You can’t undo this action.',
  buttonOpt_1: 'Deactivate',
  buttonOpt_2: 'Cancel'
};

export const warningMsgTimeDropEdit = {
  headText: 'Confirm',
  subText: 'Are you sure you want to edit this item?',
  buttonOpt_1: 'Edit',
  buttonOpt_2: 'Cancel'
};


export const auWarningMsgEdit = {
  headText: 'Please Confirm that you want to save the changes',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const editWarning = {
  headText: 'Please Confirm that You Want to Save the Changes.',
  subText: ' ',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgEditStrategyPlan = {
  headText: 'You are About to Edit the Strategy Plan!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgDeleteStrategyPlan = {
  headText: 'You are About to Delete the Strategy Plan!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};

export const auWarningMsgCancel = {
  headText: 'Are You Sure?',
  subText: 'Please confirm that you want to ignore the changes.',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const warningMsgCancel = {
  headText: 'You are About to Cancel the Uploaded Data',
  subText: 'Are You Sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const assistedModellingWarningMsgCancel = {
  headText: 'You are About to Cancel the Assisted Modeling',
  subText: 'Are You Sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const cancelWarningMsg = {
  headText: 'Please Confirm that You Want to Save the Changes.',
  subText: ' ',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgComplianceEdit = {
  headText: 'Edit Compliance',
  subText: 'Are you sure you want to edit this compliance?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const assetFormControlPanel = {
  headText: 'Update Asset Form',
  subText: 'Are you sure you want to update this Asset Form?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const incidentFormControlPanel = {
  headText: 'Edit Incident Form',
  subText: 'Are you sure you want to edit this Incident Form?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const araCpEdit = {
  headText: 'You are About to Edit a Scenario!',
  subText: 'Are you sure you?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const araCpProcess = {
  headText: 'Edit Process',
  subText: 'Are you sure you want to edit this Process?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const araCpSubProcess = {
  headText: 'Edit Sub Process',
  subText: 'Are you sure you want to edit this Sub Process?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgIncidentEdit = {
  headText: 'Edit Incident',
  subText: 'Are you sure you want to edit this  incident?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgAssetEdit = {
  headText: 'Edit Asset',
  subText: 'Are you sure you want to edit this  asset?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgScenarioDetailEdit = {
  headText: 'You are About to Edit a Scenario',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgComplianceCreate = {
  headText: 'Create a new Compliance',
  subText: 'Are you sure you want to create a new compliance?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgCreate = {
  headText: 'Add a new expense',
  subText: 'Are you sure you want to add a new expense?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgRiskDraft = {
  headText: 'Proceed to Assessment?',
  subText: 'You are proceeding to risk assessment. The risk will be saved as draft.',
  buttonOpt_1: 'Confirm',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgRiskSaveAsDraft = {
  headText: 'Save as Draft?',
  subText: 'Please confirm',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};

export const auWarningMsgAfterRiskSaveAsDraft = {
  headText: 'Your Draft is Saved',
  subText: 'The risk has been saved as a draft. Do you want to continue editing?',
  buttonOpt_1: 'Continue editing',
  buttonOpt_2: 'Exit'
};
export const auWarningMsgAuditEdit = {
  headText: 'Edit Audit',
  subText: 'Are you sure you want to edit this Audit?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgRiskProgramEdit = {
  headText: 'Edit Risk Program',
  subText: 'Are you sure you want to edit this Risk Program?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgKriEdit = {
  headText: 'Edit KRI',
  subText: 'Are you sure you want to edit this KRI?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgAuditProgramEdit = {
  headText: 'Edit Audit Program',
  subText: 'Are you sure you want to edit this Audit Program?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgStrategyEdit = {
  headText: 'You are About to Edit the Strategy!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgStrategyDelete = {
  headText: 'You are About to Delete the Business Strategy!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};

export const auWarningMsgRiskEdit = {
  headText: 'Edit Risk',
  subText: 'Are you sure you want to edit this risk?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgControlCreate = {
  headText: 'Create a new Control',
  subText: 'Are you sure you want to create a new risk control?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgControlEdit = {
  headText: 'Edit Control',
  subText: 'Are you sure you want to edit this risk control?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgConfirm = {
  headText: 'Are you sure?',
  subText: 'Please Confirm the details',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgAnalyticsCalculatedFieldEdit = {
  headText: 'Edit Field',
  subText: 'Are you sure you want to edit this field? This field is used for another calculated field creation.',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'No'
};

export const auWarningMsgScenarioCreate = {
  headText: 'Create Scenario',
  subText: 'Are you sure you want to create a scenario?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgScenarioEdit = {
  headText: 'You are About to Edit a Scenario',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgSaveChanges = {
  headText: 'Save Changes',
  subText: 'Do you want to save the changes?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgSaveChangesOnExit = {
  headText: 'Exit without saving',
  subText: 'Are you sure you want to exit without saving the changes?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const dataProcess = {
  headText: 'Data Process',
  subText: 'This will Process all Records. Are you sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgDataFlush = {
  headText: 'You are About to Flush the Data of this Data Source',
  subText: 'Are you sure you?',
  buttonOpt_1: 'Flush Data',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDataFlushOverview = {
  headText: 'All exceptions will be flushed & you should run each scenario individually.',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Flush Data',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDataFlushDetails = {
  headText: 'All exceptions will be flushed & scenario will run again.',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Flush Data',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDataFlushWithScenarios = {
  headText: 'You are About to Flush the Data to this Data Source',
  subText: 'Are you sure?',
  subText_1: 'scenario is created on this data source. Are you sure you want to flush the data?',
  subText_2: 'scenarios are created on this data source. Are you sure you want to flush the data?',
  buttonOpt_1: 'Flush Data',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDataAddAfterFlush = {
  headText: 'You are About to Add Data to this Data Source',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgLoadMoreTextData = {
  headText: 'You are About to Add More Data to this Data Source',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgException = {
  warning: 'Alert',
  headText: 'The ratings of the controls: ',
  subText: 'will be changed. Do you want to proceed?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'No'
};

export const auWarningMsgClientCreate = {
  headText: 'Create a new Client',
  subText: 'Are you sure you want to create a new client?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgClientEdit = {
  headText: 'Edit a Client',
  subText: 'Are you sure you want to edit client details?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeleteClient = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this client? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const warningMsgActivateClient = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to activate this client?',
  buttonOpt_1: 'Activate Client',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeactivateClient = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to deactivate this client?',
  buttonOpt_1: 'Deactivate Client',
  buttonOpt_2: 'Cancel'
};
export const warningMsgInstanceClient = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to create instance for this client?',
  buttonOpt_1: 'Create Instance',
  buttonOpt_2: 'Cancel'
};

export const warningMsgCreatePolicy = {
  headText: 'Create a New Policy',
  subText: 'Are you sure you want to create a new policy?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgCreatePolicyAsDraft = {
  headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Yes, Confirm',
  buttonOpt_2: 'No, Cancel'
};

export const warningMsgRestorePolicy = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to restore policy?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const warningMsgEditPolicy = {
  headText: 'Edit a Policy',
  subText: 'Are you sure you want to edit the policy?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeletePolicy = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this policy? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const policyRequestFormControlPanel = {
  headText: 'Update Request Details Form',
  subText: 'Are you sure you want to update this Request Details Form?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const policyRequestApprove = {
  headText: 'Approve Request',
  subText: 'Are you sure you want to approve this request?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const complianceRequestApprove = {
  headText: 'Approve Request',
  subText: 'Are you sure you want to approve this request?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgPolicyRequestEdit = {
  headText: 'Edit Policy Request',
  subText: 'Are you sure you want to edit this policy request?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgPolicyRequestCreate = {
  headText: 'Create a New Policy Request',
  subText: 'Are you sure you want to create a new Policy Request?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgPolicyRequestCreateAsDraft = {
  headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Yes, Confirm',
  buttonOpt_2: 'No, Cancel'
};

export const warningMsgDeletePolicyRequest = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this policy request? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeleteComplianceRequest = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this compliance request? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgReviewPolicy = {
  headText: 'Review Policy',
  subText: 'Are you sure you want to review this policy?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgEditCompliance = {
  headText: 'Edit a Compliance',
  subText: 'Are you sure you want to edit the Compliance?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteComplianceAudit = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this compliance audit? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};
export const warningMsgDeleteTimeLog = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this time log? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgCreateComplianceAudit = {
  headText: 'Create a new Compliance Audit',
  subText: 'Are you sure you want to create a new compliance audit?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const warningMsgCreateComplianceAuditAsDraft = {
  headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Yes, Confirm',
  buttonOpt_2: 'No, Cancel'
};
export const warningMsgCompliancRequestCreate = {
  headText: 'Create a new Compliance Request',
  subText: 'Are you sure you want to create a new Compliance Request?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgCompliancRequestCreateAsDraft = {
  headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Yes, Confirm',
  buttonOpt_2: 'No, Cancel'
};

export const warningMsgRestoreComplianceAudit = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to restore this citation?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgReviewComplianceAudit = {
  headText: 'Review Citation',
  subText: 'Are you sure you want to review this citation?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteCompliance = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to delete this compliance? You can’t undo this action.',
  buttonOpt_1: 'Delete',
  buttonOpt_2: 'Cancel'
};

export const warningMsgComplianceRequestEdit = {
  headText: 'Edit Compliance Request',
  subText: 'Are you sure you want to edit this compliance request?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgRequestCreate = {
  headText: 'Create a new Request',
  subText: 'Are you sure you want to create a new Request?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgRequestCreateAsDraft = {
  headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Yes, Confirm',
  buttonOpt_2: 'No, Cancel'
};


export const warningMsgRequestEdit = {
  headText: 'Edit Request',
  subText: 'Are you sure you want to edit this request?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgDeleteRequest = {
  headText: 'You are About to Remove the Request!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};


export const warningMsgAddNewDependency = {
  headText: 'Add a new dependency',
  subText: 'Are you sure you want to add a new dependency?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgAddNewDependencies = {
  headText: 'Add new dependencies',
  subText: 'Are you sure you want to add new dependencies?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgCreateRequestPolicy = {
  headText: 'Are you sure?',
  subText: 'You are leaving the policy creation wizard to create a request. Please confirm your action.',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgCreateRequestCompliance = {
  headText: 'Are you sure?',
  subText: 'You are leaving the compliance creation wizard to create a request. Please confirm your action.',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgTimeLogApprove = {
  headText: 'Approve Time Log',
  subText: 'Are you sure you want to approve this time log?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgTimeLogRequestCreate = {
  headText: 'Create a new Time Log Request',
  subText: 'Are you sure you want to create a new time log request?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const warningMsgEditBusinessProcess = {
  headText: 'Edit a business process',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgTimeLogReject = {
  headText: 'Reject Time Log',
  subText: 'Are you sure you want to reject this time log?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const warningMsgCreateBusinessProcess = {
  headText: 'Create a business process',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgAssetCreate = {
  headText: 'Create Asset',
  subText: 'Are you sure you want to create this asset?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningMsgCreateAssetAsDraft = {
 headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Yes, Confirm',
  buttonOpt_2: 'No, Cancel'
};

export const warningMsgCreateBusinessProcessAsDraft = {
  headText: 'Save as Draft ?',
  subText: 'Please confirm',
  buttonOpt_1: 'Yes, Confirm',
  buttonOpt_2: 'No, Cancel'
};

export const warningMessageRedirectToObservationWizard = {
  headText: 'You are About to Edit an Observation.',
  subText: 'You will be directed to editing wizard where you can modify the observation details and the dependencies. Do you want to proceed?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'No, Cancel'
};

export const auWarningMsgMassReAssign = {
  headText: 'Proceed to Mass Reassign?',
  subText: 'You are about to replace the selected user on CountOfRecords records. Do you want to proceed?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, don’t proceed',
  subText_1: 'You are about to replace the selected user on',
  subText_2: 'records. Do you want to proceed?',
  showCancelButton: true,
  icon: 'warning'
};

export const warningMsgErmAssessment = {
  headText: 'Proceed to Assign Assessment?',
  subText: 'You are about to assign the assessment to',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel',
  subText_1: 'You are about to assign the assessment to',
  subText_2: '. Do you want to proceed?',
  showCancelButton: true,
  icon: 'warning'
};

export const askForMassReassign = {
  headText: 'Proceed to Mass Reassign?',
  subText: 'The User has dependencies with other data. Reassign data to another user and then try deleting it again',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Cancel'
};

export const notifyMassReassign = {
  headText: 'Mass Reassign is in Progress',
  subText: 'This will take a while. You will be notified once the action is complete.',
  buttonOpt_1: 'Ok',
  buttonOpt_2: 'Cancel',
  showCancelButton: false,
  icon: 'success'
};

export const askForRecoverUser = {
  headText: 'Proceed to Recover User?',
  subText: 'This is a deleted user which already having a history with your team. Do you want to proceed to recover this user?',
  buttonOpt_1: 'Recover',
  buttonOpt_2: 'Cancel',
  showCancelButton: true,
  icon: 'warning'
};

export const warningSwLatest = {
  headText: 'Latest build is available',
  subText: 'Please click the reload button to load changes.',
  buttonOpt_1: 'Reload now',
  buttonOpt_2: 'I\'ll do it later'
};

export const warningBulkReminderCreate = {
  headText: 'Proceed to Submit Bulk Reminder?',
  subText: 'You are about to proceed with Bulk reminders for the selected records. Do you want to proceed?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, don’t proceed',
  subText_1: 'You are about to proceed with Bulk reminders for the',
  subText_2: 'selected records. Do you want to proceed?',
  showCancelButton: true,
  icon: 'warning'
};

export const sendBulkReminderEmail = {
  headText: 'Email Sending is in Progress',
  subText: 'This will take a while. You will be notified once the action is completed.',
  buttonOpt_1: 'Ok',
  buttonOpt_2: 'Cancel',
  showCancelButton: false,
  icon: 'success'
};

export const warningMsgDeleteControl = {
  headText: 'You are About to Delete Control!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don\'t delete'
};
export const warningMessageCloneControl = {
  headText: 'You are About to Create a Copy of the Control.',
  subText: 'Are you sure you want to go ahead?',
  buttonOpt_1: 'Clone',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgControlEditConfirmation = {
  headText: 'You are About to Edit the Risk Control!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgControlCreateConfirmation = {
  headText: 'You are About to Create a New Risk Control!',
  subText: 'Are you sure you want to go ahead?',
  buttonOpt_1: 'Yes, create',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgDeleteTaggedRisk = {
  headText: 'You are About to Remove the Risk!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgDeleteTaggedObservation = {
  headText: 'You are About to Remove the Observation!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const warningMsgDeleteAssessment = {
  headText: 'You are About to Remove the Assessment!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const warningMsgDeleteIncident = {
  headText: 'You are About to Remove the Incident!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const warningMsgDeleteAction = {
  headText: 'You are About to Delete Action!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don\'t delete'
};
export const auWarningMsgDeleteIssue = {
  headText: 'You are About to Delete Issue!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don\'t delete'
};
export const auWarningMsgActionEditConfirmation = {
  headText: 'You are About to Edit the Action!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgImprovementEditConfirmation = {
  headText: 'You are About to Edit the Improvement!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgImprovementNoFieldsConfirmation = {
  headText: 'No inputs are given to the form',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgIssueEditConfirmation = {
  headText: 'You are About to Edit the Issue!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMessageCloneStrategy = {
  headText: 'You are About to Create a Copy of the Strategy.',
  subText: 'Are you sure you want to go ahead?',
  buttonOpt_1: 'Clone',
  buttonOpt_2: 'Cancel'
};
export const auWarningMssgRiskCreate = {
  headText: 'You are About to Create a New Risk!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, create',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMssgObservationCreate = {
  headText: 'You are About to Create a New Observation!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, create',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMessageCloneRisk = {
  headText: 'You are About to Create a Copy of the Risk.',
  subText: 'Are you sure you want to go ahead?',
  buttonOpt_1: 'Clone',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgDeleteRisk = {
  headText: 'You are About to Delete a Risk!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgUpdateRisk = {
  headText: 'You are About to Edit a Risk!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgUpdatePlanModel = {
  headText: 'You are about to edit an audit plan model!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgRemoveTaggedControl = {
  headText: 'You are About to Remove a Control!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgRemoveTaggedIncident = {
  headText: 'You are About to Remove an Incident!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgRemoveTaggedRecommendation = {
  headText: 'You are About to Remove a Recommendation!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, remove',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgRiskRegisterEdit = {
  headText: 'You are About to Edit the Risk Register!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgRiskRegisterDelete = {
  headText: 'You are About to Delete the Risk Register!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgErmRiskRegisterDelete = {
  headText: 'You are About to Delete the ERM Register!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgErmRiskRegisterEdit = {
  headText: 'You are About to Edit the ERM Register!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const warningMsgWithdrawReminder = {
  headText: 'Are you sure?',
  subText: 'Are you sure you want to withdraw this reminder? You can’t undo this action.',
  buttonOpt_1: 'Withdraw',
  buttonOpt_2: 'Cancel',
  showCancelButton: true,
  icon: 'warning'
};
export const warningIncidentReporting = {
  headText: 'Thank You for Submitting the Incident Report',
  subText: ' ',
  buttonOpt_1: 'Ok',
  buttonOpt_2: ' ',
  subText_1: 'Use tracking ID:',
  subText_2: 'to track the incident report status.',
  showCancelButton: false,
  icon: 'success'
};

export const warningWhistleblowReporting = {
  headText: 'Thank You for Submitting the Whistleblower',
  subText: ' ',
  buttonOpt_1: 'Ok',
  buttonOpt_2: ' ',
  subText_1: 'Please take a note of your ticket ID: \n',
  subText_2: 'for future reference.',
  showCancelButton: false,
  icon: 'success'
};
export const auWarningMsgRiskCategoryEdit = {
  headText: 'You are About to Edit the Category!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgRiskCategoryDelete = {
  headText: 'You are About to Delete the Category!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMessageCloneEnterpriseRisk = {
  headText: 'You are About to Create a Copy of the Enterprise Risk.',
  subText: 'Are you sure you want to go ahead?',
  buttonOpt_1: 'Clone',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgDeleteAssessmentQuestionnaire = {
  headText: 'You are About to Delete a Question!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgDeleteAssessments = {
  headText: 'You are About to Delete an Assessment!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgDeleteAssessmentPlan = {
  headText: 'You are About to Delete an Assessment Plan!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};

export const auWarningMsgEditAssessmentPlan = {
  headText: 'You are About to Edit an Assessment Plan!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgDeleteEnterpriseRisk = {
  headText: 'You are About to Delete a Enterprise Risk!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgEnterpriseRiskSaveAsDraft = {
  headText: 'Save as Draft?',
  subText: 'Please confirm',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgAfterEnterpriseRiskSaveAsDraft = {
  headText: 'Your Draft is Saved',
  subText: 'The enterprise risk has been saved as a draft. Do you want to continue editing?',
  buttonOpt_1: 'Continue editing',
  buttonOpt_2: 'Exit'
};
export const auWarningMsgUpdateEnterpriseRisk = {
  headText: 'You are About to Edit a Enterprise Risk!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgEnterpriseRiskCreate = {
  headText: 'You are About to Create a New Enterprise Risk!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, create',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgControlPanelDelete = {
  headText: 'Are You Sure You Want to Delete this Item?',
  subText: 'You can’t undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgControlPanelEdit = {
  headText: 'Please Confirm that You Want to Save the Changes.',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgControlPanelSeverityEdit = {
  headText: 'Editing the Scoring Factor will Affect the Rating Calculations in the Existing Scoring Models',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgControlPanelSeverityDetailEdit = {
  headText: 'Editing the Scoring Factor will Affect the Rating Calculations in the Scoring Model',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgControlPanelRedirectToScoringModelEdit = {
  headText: 'You will be Redirected to the Existing Scoring Models Page',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgControlPanelRedirectToObservationScoringModelEdit = {
  headText: 'You will be Redirected to Edit the Existing Scoring Model',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgOngoingAudit = {
  headText: 'There are no On-going audits associated with the selected Risks/Controls.',
  subText: ' ',
  buttonOpt_1: 'Continue',
  buttonOpt_2: 'Cancel'
};
export const auWarningMsgOngoingRiskProgram = {
  headText: 'There are no On-going risk Program associated with the selected Risks/Controls.',
  subText: ' ',
  buttonOpt_1: 'Continue',
  buttonOpt_2: 'Cancel'
};
export const successOngGoingAudits = {
  headText: 'Successful!',
  subText: 'Selected Risks/Controls are tagged to the Audit.',
  buttonOpt_1: 'OK'
};
export const warningMsgUpdateResidualRiskRating = {
  headText: 'You are About to Edit the Residual Risk Rating!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};

export const warningMsgEditEmailTemplate = {
  headText: 'You are About to Edit the Template!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};

export const warningMsgEditEmailSignature = {
  headText: 'You are About to Edit the Signature!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};

export const auditEntityValueChanged = {
  headText: 'Entity Changed !',
  subText: 'Risks, Controls and Assessments will be fetched based on the latest entity values. Previously tagged dependencies will be removed from Audit.',
  buttonOpt_1: 'OK'
};

export const warningMsgAuditAsDraft = {
  headText: 'Your Draft is Saved',
  subText: 'The audit has been saved as a draft. Do you want to continue editing?',
  buttonOpt_1: 'Continue editing',
  buttonOpt_2: 'Exit'
};

export const auWarningMsgEditAudit = {
  headText: 'You are About to Edit an Audit!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgDeleteAudit = {
  headText: 'You are about to Delete an Audit!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};

export const auWarningMsgDeleteAuditMessage = {
  headText: 'You are about to Delete an Audit!',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};

export const auWarningMsgDeleteAuditUniverse = {
  headText: 'You are about to Delete an Audit Universe!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};

export const auWarningMsgEditAuditUniverse = {
  headText: 'You are About to Edit an Audit Universe!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgEditAuditProgram = {
  headText: 'You are about to Edit an Audit Program!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgEditAuditComment = {
  headText: 'You are about to Edit an Auditors Comment!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgEditAuditorComment = {
  headText: 'You are about to Edit an Auditor Comment!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};

export const auWarningMsgDeleteAuditProgram = {
  headText: 'You are about to Delete an Audit Program!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};

export const auWarningMsgEditActivity = {
  headText: 'You are about to Edit an Activity!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgDeleteActivity = {
  headText: 'You are about to Delete an Activity!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgAuditDraft = {
  headText: 'You are About to Delete an Audit Draft!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};

export const auWarningMsgCommentDelete = {
  headText: 'You are About to Delete the Comment!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgIssueDelete = {
  headText: 'You are About to Delete the Issue!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgEditAuditRecommendation = {
  headText: 'You are About to Edit a Recommendation!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgEditRiskProgramRecommendation = {
  headText: 'You are About to Edit a Risk Program Recommendation!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgDeleteAuditRecommendation = {
  headText: 'You are About to Delete a Recommendation!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgDeleteRiskProgramRecommendation = {
  headText: 'You are About to Delete  a Risk Program Recommendation!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgRemoveIssue = {
  headText: 'You are About to Remove an Issue!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgRemoveAssessment = {
  headText: 'You are About to Remove a Assessment!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgRemoveWorkingPaper = {
  headText: 'You are About to Remove a Working Paper!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgRiskDelete = {
  headText: 'You are About to Remove a Risk!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgControlDelete = {
  headText: 'You are About to Remove a Control!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const auWarningMsgDeleteManagementResponse = {
  headText: 'You are About to Delete the Management Response!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgEditObservation = {
  headText: 'You are About to Edit an Observation!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgDeleteObservation = {
  headText: 'You are About to Delete an Observation!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgEditImprovement = {
  headText: 'You are About to Edit an Improvement!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel changes'
};
export const auWarningMsgDeleteImprovement = {
  headText: 'You are About to Delete an Improvement!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMsgDeleteReport = {
  headText: 'You are About to Delete the Report!',
  subText: 'Are you sure? You cannot undo this action',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};
export const auWarningMessageCloneAudit = {
  headText: 'You are About to Create a Copy of the Audit.',
  subText: 'Are you sure you want to go ahead?',
  buttonOpt_1: 'Clone',
  buttonOpt_2: 'Cancel'
};

export const warningMessageEntity = {
  headText: 'You will be Redirected to Edit the Existing Scoring Model',
  subText: 'Do you want to proceed?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};

export const warningMessageEditInherentRating = {
  headText: 'Do You Want to Proceed?',
  subText: 'You are about to change inherent risk rating values. If you modify, please review inherent rating calculation in scoring models.',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};

export const warningMessageEditResidualRating = {
  headText: 'Do You Want to Proceed?',
  subText: 'You are about to change residual risk rating values. If you modify, please review residual rating calculation in scoring models.',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};

export const warningMessageAddKriRating = {
  headText: 'Are you sure?',
  subText: 'When adding a new rating value, it is necessary to update the scoring method of each KRI to ensure that auto-rated KRIs accurately reflect the new rating system.',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};

export const warningMessageEditKriRating = {
  headText: 'Are you sure?',
  subText: 'When editing a rating value, it is necessary to update the scoring method of each KRI to ensure that auto-rated KRIs accurately reflect the new rating system.',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};

export const approvalDraftSuccessForAudit = {
  headText: 'Successful!',
  buttonOpt_1: 'Ok',
  buttonOpt_2: 'View Draft',
  htmlContent: ''
};

export const auWarningMsgProgramEdit = {
  headText: 'Edit Risk Program',
  subText: 'Are you sure you want to edit this Risk Program?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const warningAuditDelete = {
  headText: 'Warning!',
  subText: ' ',
  buttonOpt_1: 'Ok',
  buttonOpt_2: ' ',
  showCancelButton: false,
  icon: 'warning'
};
export const warningMsgRemoveTaggedRiskInStrategy = {
  headText: 'You are About to Remove a Risk!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};

export const warningMsgAuditSaveAsDraft = {
  headText: 'Save as Draft?',
  subText: 'Please confirm',
  buttonOpt_1: 'Yes, Confirm',
  buttonOpt_2: 'No, Cancel'
};

export const warningAfterMsgAuditSaveAsDraft = {
  headText: 'Your Draft is Saved',
  subText: 'The Audit Planning has been saved as a draft. Do you want to continue editing?',
  buttonOpt_1: 'Continue editing',
  buttonOpt_2: 'Exit'
};
export const warningMsgPublishFlow = {
  headText: 'You are About to Publish the flow',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgEditMenu = {
  headText: 'You are About to Edit Menu Items!',
  subText: 'Are you sure?',
  buttonOpt_1: 'Yes, Edit',
  buttonOpt_2: 'Cancel'
};

export const auWarningMsgDataprocess = {
  headText: 'Data is processing please wait',
  subText: 'Data is processing please wait, generating the data',
  buttonOpt_1: 'Ok',
  buttonOpt_2: 'Cancel',
  showCancelButton: false,
  icon: 'warning'
};

export const auWarningMsgDeleteEmailTemplate = {
  headText: 'You are About to Delete a Template!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, delete forever',
  buttonOpt_2: 'No, don’t delete'
};

export const auWarningMsgDeleteTaggedControl = {
  headText: 'You are About to Remove the Control!',
  subText: 'Are you sure? You cannot undo this action.',
  buttonOpt_1: 'Yes, confirm',
  buttonOpt_2: 'No, cancel'
};
export const warningMessageAddDynamicControlRating = {
  headText: 'Do You Want to Proceed?',
  subText: 'You are about to add Dynamic Rating Factor. If you modify, please review control rating calculation in Dynamic Rating.',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};

export const warningMessageEditDynamicControlRating = {
  headText: 'Do You Want to Proceed?',
  subText: 'You are about to change Dynamic Rating Factor. If you modify, please review control rating calculation in Dynamic Rating.',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
};

export const warningMeassageEntityChanged = {
  headText: 'You Are About to Update the Entity.',
  subText: 'Only those risks and controls associated with entity will be listed for selection in future changes to the audit program.',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Undo'
}

export const warningMeassageEntityChangedKri = {
  headText: 'You Are About to Update the Entity.',
  subText: 'Only those risks, controls and processes associated with entity will be listed for selection in future changes to the KRI.',
  buttonOpt_1: 'Proceed',
  buttonOpt_2: 'Undo'
}

export const warningMessageForAuditSurveyContributor = {
  headText: 'Changes cannot be made after submission.',
  subText: 'Do you want to continue?',
  buttonOpt_1: 'Yes, proceed',
  buttonOpt_2: 'No, cancel'
}
