import { NotfoundComponent } from './authentication/404/not-found.component';
import { ResolveTabGuard } from './core/guard/resolvetab.guard';
import { Routes } from '@angular/router';
import { ResolveGuard } from './core/guard/resolve.guard';
import { ResolveContributorGuard } from './core/guard/resolve-contributor.guard';
import { ResolveAdminGuard } from './core/guard/resolveadmin.guard';

const routes: Routes = [
  { path: 'dashboard/home', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    loadChildren:
    () => import('./layouts/blank/blank.module').then((m:typeof import('./layouts/blank/blank.module')) => m.BlankModule)
  },
  {
    path: '',
    loadChildren:
    () => import('./layouts/full/full.module').then((m:typeof import('./layouts/full/full.module')) => m.FullModule),
    resolve: {
      data: ResolveGuard,
      tab: ResolveTabGuard,
      dataContributor: ResolveContributorGuard,
      dataadmin: ResolveAdminGuard
    }
  },
  {
    path: '404',
    component: NotfoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

export const appRoutes: Routes = routes;
