import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import { FunctionService } from '../helper/function.helper.service';
import { AdminUserService } from '../service/control-panel/admin-user.service';

@Injectable({
  providedIn: 'root'
})

export class ResolveContributorGuard implements Resolve<any> {
  constructor(public _admin: AdminUserService,
    public _helper: FunctionService) {}

  resolve() {
    const userRole = this._helper?.getUser?.role;
    if (userRole !== 'PROFESSIONAL') {
      return this._admin.getContributorSidebar();
    }
  }
}
