import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { JwtTokenService } from '../helper/jwt-token.service';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  constructor( private _jwtTokenService :JwtTokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {

    const idToken = this._jwtTokenService.getToken !== '' ? this._jwtTokenService.getToken : false;
    const user =  this._jwtTokenService.getTokenData;
    if (idToken) {
      let url = request.url.replace('http://', `${environment.protocol}`);
      url = url.replace(`${environment.baseUrl}`, `${environment.protocol}${user.domain}`);
      const cloned = request.clone({
        headers: request.headers.set('Authorization', `JWT ${idToken}`),
        url: url,
        withCredentials: true
      });
      return next.handle(cloned);
    }
    if (request.url.includes('audit-html-detail')) {
      let url = request.url.replace('http://', `${environment.protocol}`);
      url = url.replace(`${environment.domain}`, `${window.location.host}`);
      const cloned = request.clone({
        url: url,
        withCredentials: true
      });
      return next.handle(cloned);
    }
    const cloned = request.clone({
      withCredentials: true
    });
    return next.handle(cloned);


  }
}
